/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Carousel } from "react-bootstrap";
import "./Products.scss";
import grant from "../../assets/img/grants.svg";
import database from "../../assets/img/database.svg";
import trade from "../../assets/img/trade.svg";
import trade2 from "../../assets/img/trade-finance.svg";
import { Link } from "react-router-dom";

const Products = () => {
  return (
    <section className="products ">
      <Carousel variant="dark">
        <Carousel.Item>
          <div className="offer">
            <div className="px">
              <div className="relative-div">
                <img className="icon-abs-1" src={grant} alt="" />
                <img className="icon-abs-2" src={database} alt="" />
                <img className="icon-abs-3" src={trade} alt="" />
                <img className="icon-abs-4" src={trade2} alt="" />
              </div>
              <div className="offer-container">
                <div className="offer-title-container">
                  <h4 className="offer-title">TradeBuza AgroAPI</h4>
                  <p className="offer-subtitle">
                    Leverage our API infrastructure to validate your operational
                    and agriculture financing processes.
                  </p>

                  <Link to="/agro-api">
                    <button className="banner-button">Learn more</button>
                  </Link>
                </div>
                <div className="offer-card-container-1">
                  <div className="offer-card1">
                    <h4 className="offer-card-title1">Farm Monitoring</h4>
                    <p className="offer-card-subtitle1">
                      Receive standardized and aggregated real-time imagery from
                      major satellite providers. Monitor your field health
                      evolution over time.
                    </p>
                  </div>
                  <div className="offer-card1">
                    <h4 className="offer-card-title1">Farm Validation</h4>
                    <p className="offer-card-subtitle1">
                      Get consistent and instant validation checks for your
                      preferred farmlands, with full datasets and summaries
                      based on GPS coordinates and rendered maps.
                    </p>
                  </div>
                </div>
                <div className="offer-card-container-2">
                  <div className="offer-card1">
                    <h4 className="offer-card-title1">Payment Services</h4>
                    <p className="offer-card-subtitle1">
                      Disburse loans, salaries, and sales contributions in an
                      easy and organized manner.
                    </p>
                  </div>

                  <div className="offer-card1">
                    <h4 className="offer-card-title1">Pre-loan Assessment</h4>
                    <p className="offer-card-subtitle1">
                      Monitor and manage ag-lending operations and portfolio at
                      scale.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="offer">
            <div className="px">
              <div className="relative-div">
                <img className="icon-abs-1" src={grant} alt="" />
                <img className="icon-abs-2" src={database} alt="" />
                <img className="icon-abs-3" src={trade} alt="" />
                <img className="icon-abs-4" src={trade2} alt="" />
              </div>

              <div className="offer-container">
                <div className="offer-title-container">
                  <h4 className="offer-title">TradeBuza Agro</h4>
                  <p className="offer-subtitle">
                    Monitor and track the activities of outgrower schemes and
                    aggregators from preproduction through harvest.
                  </p>

                  <Link to="/agro">
                    <button className="banner-button">Learn more</button>
                  </Link>
                </div>
                <div className="offer-card-container-1">
                  <div className="offer-card1">
                    <h4 className="offer-card-title1">
                      Near Real-Time Visibility
                    </h4>
                    <p className="offer-card-subtitle1">
                      View field activities captured digitally by field agents
                      in real-time.
                    </p>
                  </div>
                  <div className="offer-card1">
                    <h4 className="offer-card-title1">Payment Services</h4>
                    <p className="offer-card-subtitle1">
                      Automate your payments and transactions processing within
                      the Outgrower scheme, with Input suppliers, Agents &
                      Farmers.
                    </p>
                  </div>
                </div>
                <div className="offer-card-container-2">
                  <div className="offer-card1">
                    <h4 className="offer-card-title1">Data Management</h4>
                    <p className="offer-card-subtitle1">
                      View and audit farmers, field staff and farm-lot data
                      while eliminating bulky paper based processes.
                    </p>
                  </div>
                  <div className="offer-card1">
                    <h4 className="offer-card-title1">Commodities Trade</h4>
                    <p className="offer-card-subtitle1">
                      We facilitate commodities trade to a network of
                      buyers/off-takers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="offer">
            <div className="px">
              <div className="relative-div">
                <img className="icon-abs-1" src={grant} alt="" />
                <img className="icon-abs-2" src={database} alt="" />
                <img className="icon-abs-3" src={trade} alt="" />
                <img className="icon-abs-4" src={trade2} alt="" />
              </div>
              <div className="offer-container">
                <div className="offer-title-container">
                  <h4 className="offer-title">TradeBuza Capture</h4>
                  <p className="offer-subtitle">
                    Take advantage of our end-to-end field enumeration and farm
                    management system to perform KYC collection, validation,
                    field GPS mapping, customized reporting & eliminate false
                    activity readings.
                  </p>
                  <Link to="/capture">
                    <button className="banner-button">Learn more</button>
                  </Link>
                </div>
                <div className="offer-card-container-1 pull_ups">
                  <div className="offer-card1">
                    <h4 className="offer-card-title1">
                      Custom Data Capture and Management
                    </h4>
                    <p className="offer-card-subtitle1">
                      Capture real-time data sets to track agricultural
                      operations. Leverage our customized validation components
                      that meet quality requirements.
                    </p>
                  </div>
                  <div className="offer-card1">
                    <h4 className="offer-card-title1">Field GPS Mapping</h4>
                    <p className="offer-card-subtitle1">
                      Improve transparency of farm lot activity, promote
                      Geofencing, and view images from field agents and
                      supervisors.
                    </p>
                  </div>
                </div>
                <div className="offer-card-container-2 pull_up">
                  <div className="offer-card1">
                    <h4 className="offer-card-title1">
                      Seamless Offline Capabilities
                    </h4>
                    <p className="offer-card-subtitle1">
                      Replace paper and spreadsheets with our Cloud-Based web
                      and mobile solutions. Capture farm data offline from
                      remote locations.
                    </p>
                  </div>
                  <div className="offer-card1">
                    <h4 className="offer-card-title1">
                      User Rights Management
                    </h4>
                    <p className="offer-card-subtitle1">
                      Manage user access and assign roles and permissions among
                      agents, project managers, and field supervisors.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="offer">
            <div className="px">
              <div className="relative-div">
                <img className="icon-abs-1" src={grant} alt="" />
                <img className="icon-abs-2" src={database} alt="" />
                <img className="icon-abs-3" src={trade} alt="" />
                <img className="icon-abs-4" src={trade2} alt="" />
              </div>
              <div className="offer-container">
                <div className="offer-title-container">
                  <h4 className="offer-title">CropGate</h4>
                  <p className="offer-subtitle">
                    Digitize your aggregation and supply operations. Manage,
                    track, & coordinate procurement and aggregation of agro
                    commodities.
                  </p>
                  <a href="https://www.cropgate.co/" target="_blank">
                    <button className="banner-button">Learn more</button>
                  </a>
                </div>
                <div className="offer-card-container-1 pull_ups">
                  <div className="offer-card1">
                    <h4 className="offer-card-title1">
                      Verify and validate inventory quantity and conditions.{" "}
                    </h4>
                    <p className="offer-card-subtitle1">
                      Capture and upload quality images of inventory for
                      accurate verification of information and meticulous
                      tracking of conditions.
                    </p>
                  </div>
                  <div className="offer-card1">
                    <h4 className="offer-card-title1">
                      Disburse payments to suppliers and agents easily
                    </h4>
                    <p className="offer-card-subtitle1">
                      Say goodbye to payment bottlenecks and enhance supplier
                      relationships with our reliable payment system. Eliminate
                      manual errors by ensuring timely and accurate payments to
                      valued suppliers
                    </p>
                  </div>
                </div>
                <div className="offer-card-container-2 pull_up">
                  <div className="offer-card1">
                    <h4 className="offer-card-title1">
                      Gain valuable insights about your aggregation activities
                    </h4>
                    <p className="offer-card-subtitle1">
                      Access timely data on your recent and past aggregation
                      activities to make informed decisions and gain valuable
                      insights into your aggregation journey
                    </p>
                  </div>
                  <div className="offer-card1">
                    <h4 className="offer-card-title1">
                      Manage inventory from your phone, tablet and
                      computer-anytime, anywhere{" "}
                    </h4>
                    <p className="offer-card-subtitle1">
                      Stay in control no matter where you are, ensuring that
                      your inventory is always up-to-date and perfectly managed.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </section>
  );
};

export default Products;
