import React from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo.jpg";
import "./TopNav.scss";

const TopNav = () => {
  return (
    <div className='top_nav container-fluid'>
      <Navbar bg='' expand='lg'>
        {/* <Container> */}
          <Navbar.Brand href='#home'>
            <Link to='/' className='logo-container'>
              <img src={logo} alt='Tradebuza Logo' />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='ms-auto'>
              <NavDropdown title='Who We Are' id='basic-nav-dropdown'>
                <NavDropdown.Item href='#action/3.1'>
                  <Link to='/teampage'>Our Team</Link>
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title='What We Offer' id='basic-nav-dropdown'>
                <NavDropdown.Item href='#action/3.1'>
                  {/* <a
                    href='https://capture.tradebuza.com/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    TradeBuza Capture
                  </a> */}
                  <Link to='/capture'>TradeBuza Capture</Link>
                </NavDropdown.Item>
                <NavDropdown.Item href='#action/3.2'>
                  {/* <a
                    href='https://agroapi.tradebuza.com'
                    target='_blank'
                    rel='noreferrer'
                  >
                    TradeBuza AgroAPI
                  </a> */}
                  <Link to='/agro-api'>TradeBuza AgroAPI</Link>
                </NavDropdown.Item>
                <NavDropdown.Item href='#action/3.3'>
                  {/* <a
                    href='https://agro.tradebuza.com/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    TradeBuza Agro
                  </a> */}
                  <Link to='/agro'>TradeBuza Agro</Link>
                </NavDropdown.Item>
                <NavDropdown.Item href='#action/3.4'>
                  <a
                    href='https://cropgate.co/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    CropGate
                  </a> 
                </NavDropdown.Item>
              </NavDropdown>

              {/* <Nav.Link href='#footer'>Contact Us</Nav.Link> */}
              <Nav.Link>
                <Link to='/referral'>Referral</Link>
              </Nav.Link>
              <NavDropdown title='Login' id='basic-nav-dropdown'>
                <NavDropdown.Item href='#action/3.1'>
                  <a
                    href='http://agroapp.tradebuza.com/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    TradeBuza Capture
                  </a>
                </NavDropdown.Item>
                <NavDropdown.Item href='#action/3.2'>
                  <a
                    href='https://tie.tradebuza.com/login'
                    target='_blank'
                    rel='noreferrer'
                  >
                    TradeBuza AgroAPI
                  </a>
                </NavDropdown.Item>
                <NavDropdown.Item href='#action/3.3'>
                  <a
                    href='http://agroapp.tradebuza.com/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    TradeBuza Agro
                  </a>
                </NavDropdown.Item>
                <NavDropdown.Item href='#action/3.4'>
                  <a
                    href='https://app.cropgate.co/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    CropGate
                  </a>
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link>
                <Link to='/get-started' className='get_started'>
                  {/* <a
                    href='http://demo.tradebuza.com/'
                    target='_blank'
                    rel='noreferrer'
                    className='get_started'
                  > */}
                    Get Started
                  {/* </a> */}
                </Link>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        {/* </Container> */}
      </Navbar>
    </div>
  );
};

export default TopNav;
