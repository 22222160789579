import React from "react";
import SecondNav from "../../components/secondNav/SecondNav";
import "./AgroWeb.scss";
import farmerImage from "../../assets/img/farmer-image.svg";
import Footer from "../../components/footer/Footer";

const AgroService = () => {
  return (
    <div className='page__wrapper'>
      <div className='rt-bg2'></div>
      <SecondNav
        service='/agro-services'
        logIn='https://tie.tradebuza.com/dashboard'
      />
      <div className='page__container'>
        <div className='hero__wrapper'>
          <div className='hero__img__sm'>
            <img src={farmerImage} alt='img' srcSet='' />
          </div>
          <div className='hero__text'>
            <h4 className='banner-heading'>TradeBuza Agro</h4>
            <p className='banner-subtitle'>
              Built to leverage a combination of a web application and mobile
              application (used by field/extension agents) and satellite/remote
              sensing tech for efficient Outgrower management and effective
              monitoring of adherence to Food safety and sustainability
              standards on the field.
            </p>
          </div>
          <div className='hero__img'>
            <img src={farmerImage} alt='img' srcSet='' />
          </div>
        </div>

        <div className='banner-cards'>
          <div className='card'>
            <h4 className='card-title'>
              {" "}
              Near Real-time Visibility into Farm Activities
            </h4>
            <p className='card-subtitle'>
              We empower field/extension agents to report activities on the farm
              using our mobile app which makes it easy to view field activities
              captured digitally by field agents in real-time.
            </p>
          </div>
          <div className='card'>
            <h4 className='card-title'>Seamless Payment Processing</h4>
            <p className='card-subtitle'>
              Automate your payments and transactions processing within the
              Outgrower scheme, and with Input suppliers, Agents & Farmers.
            </p>
          </div>

          <div className='card'>
            <h4 className='card-title'>Data Management</h4>
            <p className='card-subtitle'>
              We enable standardized audit and management for farmers, field
              staff, and farm-lot data while eliminating bulky paper-based
              processes.
            </p>
          </div>

          <div className='card'>
            <h4 className='card-title'>Simplified Reporting</h4>
            <p className='card-subtitle'>
              Our platform makes it easy to identify all farms behind schedule,
              reducing travel costs and increasing efficiency.
            </p>
          </div>
          <div className='card'>
            <h4 className='card-title'>Enhanced Communication</h4>
            <p className='card-subtitle'>
              We enable seamless communication with agents, lead farmers &
              smallholder farmers in the scheme through a structured
              communication module on our platform.
            </p>
          </div>

          <div className='card'>
            <h4 className='card-title'>Commodities Trade</h4>
            <p className='card-subtitle'>
              We facilitate commodities trade to a network of buyers/off-takers.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AgroService;
