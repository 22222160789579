import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/footer/Footer";
import PrivacyPolicyHero from "../../components/privacyPolicy/PrivacyPolicyHero";
// import NavBar from "../../components/NavBar/NavBar";
import TopNav from "../../components/TopNav/TopNav";

const PrivacyPolicyPage = () => {
  return (
    <div>
      <Helmet>
        <title>TradeBuza - Privacy Policy</title>
        <meta name='description' content='Schedule a demo to get started.' />
        <link rel='canonical' href='https://www.tradebuza.com/privacy-policy' />
      </Helmet>
      {/* <NavBar /> */}
      <TopNav />
      <PrivacyPolicyHero />
      <Footer />
    </div>
  );
};

export default PrivacyPolicyPage;