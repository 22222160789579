import React from "react";
import Advert from "../../components/blog/advert/Advert";
import Categories from "../../components/blog/blogCategories/Categories";
// import BlogFooter from "../../components/blog/blogFooter/BlogFooter";
import Hero from "../../components/blog/blogsHero/Hero";
import Latest from "../../components/blog/blogsLatest/Latest";
import Footer from "../../components/footer/Footer";
// import Subscribe from "../../components/blog/subscribe/Subscribe";
import TopNav from "../../components/TopNav/TopNav";
import "./Blogs.scss";
import { Helmet } from "react-helmet";

const Blogs = () => {
  return (
    <>
      <Helmet>
        <title>TradeBuza - Blog</title>
        <meta
          name='description'
          content='Articles on Agritech, industry insights, company news, and more.'
        />
        <link rel='canonical' href='https://www.tradebuza.com/blog' />
      </Helmet>
      <TopNav />
      <div className='blog-wrapper'>
        <Hero />
        <Latest />
        <Categories />
        <Advert />
        {/* <Subscribe /> */}
        {/* <BlogFooter /> */}
        <Footer />
      </div>
    </>
  );
};

export default Blogs;
