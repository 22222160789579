import React from "react";
import "./WhoWeAre.scss";
import outgrowers from "../../assets/img/outgrowers.svg";
import finance from "../../assets/img/trade-finance.svg";
import trade from "../../assets/img/trade.svg";

const WhoWeAre = () => {
  return (
    <section className='who'>
      <div className='px'>
        <div className='about-title'>
          <h2>
            Data and API Infrastructure | Farm Management | Agricultural Credit
            Risk Assessment{" "}
          </h2>
          <p>
            Our solutions cater to a broad range of clients from large growers,
            multi-farm corporations, ag-buyers, food conglomerates,
            ag-financing, and insurance companies, to government organizations.
            {/* TradeBuza leverages technology to help our clients manage their
            <span className='green-text'> outgrower </span>
            schemes and
            <span className='green-text'> commodities trade </span> more
            effectively. We improve efficiency, provide visibility and
            transparency into outgrowers and
            <span className='green-text'>
              {" "}
              commodities sourcing activities.
            </span> */}
          </p>
        </div>
        <div className='icon-card-container'>
          <div className='icon-card'>
            <img src={outgrowers} alt='Outgrowers' srcSet='' />
            <h4 className='card-title'>Agribusinesses</h4>
            <p className='card-text'>
              Take away the hassle of{" "}
              <span className='green-text'>farm management</span>, monitor
              performance & manage all stages of your{" "}
              <span className='green-text'>agribusiness</span> from anywhere in
              the world.
            </p>
          </div>
          <div className='icon-card'>
            <img src={trade} alt='Trade' srcSet='' />
            <h4 className='card-title'>
              Financial and Government Institutions
            </h4>
            <p className='card-text'>
              Gain visibility of farm operational data to speed up & mitigate{" "}
              <span className='green-text'>agricultural credit risks</span>.
            </p>
          </div>
          <div className='icon-card'>
            <img src={finance} alt='AgroAPIs' srcSet='' />
            <h4 className='card-title'>
              Farmer Allied Organizations, and Cooperatives
            </h4>
            <p className='card-text'>
              Empower <span className='green-text'>smallholder farmers</span>{" "}
              with technology to realize their production potential and increase
              their incomes.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;
