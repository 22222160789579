import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "./GetStartedHero.scss";
import axios from "axios";
import { TRADEBUZA_URL } from "../../configs/api";
import ModalMessage from "../modal/ModalMessage";

const GetStartedHero = () => {
  const [isLoading, SetIsLoading] = useState(false);
  const [modalIsOpen, SetModalIsOpen] = useState(false);
  const [msg, setMsg] = useState("");

  const handleModalClose = () => {
    SetModalIsOpen(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const registerOptions = {
    name: {
      required: "Enter name",
    },
    email: {
      required: "Enter email",
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: "Invalid email",
      },
    },
    number: {
      required: "Enter phone number",
    },
    userName: {
      required: "Enter name",
    },
    userEmail: {
      required: "Enter email",
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: "Invalid email",
      },
    },
    userNumber: {
      required: "Enter phone number",
    },
  };

  // on form submit
  const handleSubmitForm = async (data) => {
    SetIsLoading(true);

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios
      .post(
        `${TRADEBUZA_URL}/create-new-lead`,
        {
          name: data.name,
          email: data.email,
          phone: data.number,
        },
        config
      )

      .then((response) => {
        console.log("response", response);
        SetIsLoading(false);
        window.open("http://demo.tradebuza.com/", "_blank");
      })
      .catch((error) => {
        SetIsLoading(false);
        setMsg(error.response.data.error);
        console.log("error", error.response.data.error);
        SetModalIsOpen(true);
      });

  };

  return (
    <div className='referral'>
      <header className='cta1'>
        <div className='cta1__wrapper'>
          <div className='content'>
            <div className='cta1__wrapper__header'>
              <h1>Schedule A Demo</h1>
              <p>
                Please fill out the form to schedule a free trial. You can also
                call us at 09084759645.
              </p>
            </div>

            <form
              className='cta1__form'
              onSubmit={handleSubmit(handleSubmitForm)}
            >
              <div className='form-input-group'>
                <label htmlFor='name' className='form-input-label' hidden>
                  Name
                </label>
                <input
                  type='text'
                  className='form-input'
                  id='name'
                  placeholder='Name'
                  {...register("name", registerOptions.name)}
                />
              </div>
              <p className='error-message'>
                {errors.name && errors.name.message}
              </p>

              <div className='form-input-group'>
                <label htmlFor='email' className='form-input-label' hidden>
                  E-mail
                </label>
                <input
                  type='text'
                  className='form-input'
                  id='email'
                  placeholder='E-mail'
                  {...register("email", registerOptions.email)}
                />
              </div>
              <p className='error-message'>
                {errors.email && errors.email.message}
              </p>

              <div className='form-input-group'>
                <label htmlFor='number' className='form-input-label' hidden>
                  Phone number
                </label>
                <input
                  type='tel'
                  className='form-input'
                  id='number'
                  placeholder='Phone Number'
                  {...register("number", registerOptions.number)}
                />
              </div>
              <p className='error-message'>
                {errors.number && errors.number.message}
              </p>
              <div className='form-input-group'>
                <button
                  className='form-input button'
                  onSubmit={handleSubmit(handleSubmitForm)}
                >
                  {isLoading ? "Loading..." : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </header>

      {modalIsOpen && (
        <ModalMessage handleModalClose={handleModalClose} msg={msg} modalIsOpen={modalIsOpen} />
      )}
    </div>
  );
};

export default GetStartedHero;
