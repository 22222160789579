import React from "react";
import "./Hero.scss";

const Hero = () => {
  return (
    <div className='hero-section'>
      <div className='hero-section-content'>
        <h1 className='hero-title'>
          Articles on Agritech, industry insights, company news, and more.
        </h1>
        {/* <p className='hero-subtitle'>Posted by Lateef Jakande • 20 mins ago</p> */}
      </div>
    </div>
  );
};

export default Hero;
