import React from "react";
import { Link } from "react-router-dom";
import "./ScheduleDemo.scss";

const ScheduleDemo = () => {
  return (
    <section className='schedule'>
      <div className='px'>
        <div className='schedule-container'>
          <div className='tall'>
            <h4 className='schedule-title'>Digitize Agriculture Operations</h4>
            <p className='schedule-text'>
            Leveraging smart data-driven practices to improve agricultural productivity and efficiency.
            </p>
          </div>
          <div className='action__btn mt-4'>
            <Link to='/get-started'>
              <button className='banner-button'>Schedule A Demo</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ScheduleDemo;
