import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./SideBar.scss";

const AdminSideBar = () => {

  const navigate = useNavigate();
  
  const handleLogOut = () => {
    localStorage.removeItem("token");
    navigate("/admin");
  };
  return (
    <div className='sidebar_section'>

      <div className='side-bar-wrapper'>
        <div className='nav-links'>
          <ul>
            <Link to=''>
              <li>
                <span>Add Blog</span>
              </li>
            </Link>
            <Link to='/admin-dashboard/view-blogs'>
              <li>
                <span>View Blogs</span>
              </li>
            </Link>
            <Link to='/admin-dashboard/category'>
              <li>
                <span>Category</span>
              </li>
            </Link>
            <Link to='/admin-dashboard/tag'>
              <li>
                <span>Tag</span>
              </li>
            </Link>
            <li style={{cursor: "pointer"}} onClick={() => handleLogOut()}>Logout</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdminSideBar;
