import React from "react";
import SecondNav from "../../components/secondNav/SecondNav";
// import "./AgroApiWeb.scss";
import farmerImage from "../../assets/img/farmer-image.svg";
import Footer from "../../components/footer/Footer";

const AgroApiService = () => {
  return (
    <div className='page__wrapper'>
      <div className="rt-bg2">
      </div>
      <SecondNav
        service='/agroapi-services'
        logIn='https://tie.tradebuza.com/dashboard'
      />
      <div className='page__container'>
        <div className='hero__wrapper'>
          <div className='hero__img__sm'>
            <img src={farmerImage} alt='img' srcSet='' />
          </div>
          <div className='hero__text'>
            <h4 className='banner-heading'>TradeBuza AgroAPI</h4>
            <p className='banner-subtitle'>
              An API Infrastructure (built to leverage Remote Sensing &
              Satellite imagery, Agronomic Science and Meteorology Data) and
              provide API services to different organisations (Agritech
              innovators, Agric Lenders, Agric Insurance, DFIs, Food & Beverage
              Manufacturers etc) to automate some of their operational and
              credit risk activities.
            </p>
          </div>
          <div className='hero__img'>
            <img src={farmerImage} alt='img' srcSet='' />
          </div>
        </div>

        <div className='banner-cards'>
          <div className='card'>
            <h4 className='card-title'> FARM VALIDATION</h4>
            <p className='card-subtitle'>
              Validation checks for farmlands are often inconsistent across
              applications and use cases. Agro API provides a consistent and
              instant validation check for farmlands based on their GPS
              coordinates, which is specific to standardised location check,
              cropland vegetation check, irrigation access check, foliage
              identification, duplicate farmland checks & historic vegetation
              check.
            </p>
          </div>
          <div className='card'>
            <h4 className='card-title'>FARM MONITORING</h4>
            <p className='card-subtitle'>
              Access standardized and aggregated imagery from major satellite
              providers. Our service provides real-time Week on Week (WoW) &
              Month on Month (MoM) sustainable farm monitoring and enables
              seamless data monitoring, collection, and cloud infrastructure for
              data processing to deliver information in a readable format. Get
              started today with TradeBuza’s satellite imagery.
            </p>
          </div>

          <div className='card'>
            <h4 className='card-title'>PAYMENT SERVICES</h4>
            <p className='card-subtitle'>
              Payment Services (Wallet, Disbursement and Repayment) helps
              financially include smallholder farmers by enabling smallholders
              receive payments for their produce in their accounts/wallets to
              reduce the risks tied to cash handling and promote convenient/easy
              status reporting. In addition, other financial services that will
              be of immense benefit to smallholder farmers would be included.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AgroApiService;
