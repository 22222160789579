import React from "react";
import { Helmet } from "react-helmet";
import BlogDetails from "../../components/blog/blogDetails/BlogDetails";
// import BlogFooter from "../../components/blog/blogFooter/BlogFooter";
import Related from "../../components/blog/blogsRelated/Related";
import Footer from "../../components/footer/Footer";
import TopNav from "../../components/TopNav/TopNav";
import "./Blogs.scss";

const Blog = () => {
  return (
    <>
      <Helmet>
        <title>TradeBuza - Blog</title>
        <meta
          name='description'
          content='Articles on Agritech, industry insights, company news, and more.'
        />
        <link rel='canonical' href='https://www.tradebuza.com/blog' />
      </Helmet>
      <TopNav />
      <div className='blog-wrapper'>
        <BlogDetails />
        <Related />
        <Footer />
      </div>
    </>
  );
};

export default Blog;
