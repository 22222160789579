import React from "react";
import "./SecondNav.scss";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo.jpg";

const SecondNav = ({ service, logIn }) => {
  return (
    <div className='top_nav container-fluid'>
      <Navbar bg='' expand='lg'>
        <Navbar.Brand href='#home'>
          <Link to='/' className='logo-container'>
            <img src={logo} alt='Tradebuza Logo' />
          </Link>
        </Navbar.Brand>
        {/* <Nav.Link>
                <Link to='/services'>Services</Link>
              </Nav.Link> */}
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          {/* <Nav.Link>
            <Link to={service}>Services</Link>
          </Nav.Link> */}
          <Nav className='ms-auto'>
            <Nav.Link>
              <a href={logIn}>Log In</a>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default SecondNav;
