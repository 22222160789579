import React from "react";
import "./TeamMembers.scss";
import nonso from "../../assets/img/png/Nonso.png";
import ope from "../../assets/img/TradeBuza Team Picture 1.png";
import david from "../../assets/img/team/david.png";
import olamide from "../../assets/img/png/Mide.png";
// import nsima from "../../assets/img/team/nsima.png";
import chisom from "../../assets/img/team/chisom.png";
import chimdi from "../../assets/img/team/chimdi.png";
import sammy from "../../assets/img/sammy1.png";
import jerry from "../../assets/img/team/jerry.png";
import elijah from "../../assets/img/team/elijah.png";
import queenette from "../../assets/img/team/queenette.png";
import funke from "../../assets/img/team/funke.png";
// import aderogba from "../../assets/img/team/aderogba.png";
import ayotomiwa from "../../assets/img/team/ayotomiwa.png";
import rabiu from "../../assets/img/team/rabiu.png";
import chuka from "../../assets/img/team/chuka.png";
import Adeola from "../../assets/img/Adeola.png";

const TeamMembers = () => {
  return (
    <>
      <div className="team-page_intro px">
        {/* <!-- <div className="left-pattern">
          <img src="img/left-group.svg" alt="" srcSet="" />
        </div>
        <div className="right-pattern">
          <img src="img/right-group.svg" alt="" srcSet="" />
        </div> --> */}

        <div className="team_hero">
          <h1 className="partner-title">Our Team</h1>
          <p>
            We are a diverse, close-knit team with years of experience across
            different industries
          </p>
        </div>
      </div>

      <div className="team-members px">
        <div className="container team_member_wrapper">
          <div className="team_member nonso">
            <img src={nonso} alt="team" />
            <p>Nonso Eze</p>
            <h2>C.E.O</h2>
          </div>
          <div className="team_member">
            <img src={ope} alt="team" />
            <p>Opeyemi Kufoniyi</p>
            <h2>C.O.O</h2>
          </div>
          {/* <!-- <div className="team_member">
            <img src="./img/TradeBuza Team F2.png" alt="team" />
            <p>Tobi Ololade</p>
            <h2>C.T.O</h2>
          </div> --> */}
          {/* <!-- <div className="team_member">
            <img src="./img/TradeBuza Team F2.png" alt="team" />
            <p>Nnanna Eze</p>
            <h2>PRODUCT MANAGER</h2>
          </div> --> */}
          <div className="team_member">
            <img src={david} alt="team" />
            <p>David Aremu</p>
            <h2>REMOTE SENSING ENGINEER</h2>
          </div>
          <div className="team_member">
            <img src={olamide} alt="team" />
            <p>Egundeyi Olamide</p>
            <h2>BACKEND DEVELOPER</h2>
          </div>
          {/* <div className="team_member">
            <img src={nsima} alt="team" />
            <p>Nsima Edeheudim</p>
            <h2>SOFTWARE DEVELOPER (BACKEND CORE)</h2>
          </div> */}
          <div className="team_member">
            <img src={chisom} alt="team" />
            <p>Chisom Ekwuribe</p>
            <h2>FRONTEND ENGINEER</h2>
          </div>
          <div className="team_member">
            <img src={chimdi} alt="team" />
            <p>Chimdi Nwawueze</p>
            <h2>QUALITY ASSURANCE (SOFTWARE TESTER)</h2>
          </div>
          <div className="team_member">
            <img src={jerry} alt="team" />
            <p>Jerry</p>
            <h2>SOFTWARE ENGINEER</h2>
          </div>
          <div className="team_member">
            <img src={elijah} alt="team" />
            <p>Elijah Obominuru</p>
            <h2>FRONTEND DEVELOPER</h2>
          </div>
          <div className="team_member">
            <img src={queenette} alt="team" />
            <p>Queenette Obike</p>
            <h2>BUSINESS DEVELOPMENT</h2>
          </div>
          <div className="team_member">
            <img src={funke} alt="team" />
            <p>Funke Awogbade</p>
            <h2>BUSINESS ANALYST</h2>
          </div>
          {/* <div className="team_member">
            <img src={aderogba} alt="team" />
            <p>Taiwo Aderogba</p>
            <h2>SOCIAL MEDIA MANAGER</h2>
          </div> */}

          <div className="team_member">
            <img src={ayotomiwa} alt="team" />
            <p>Ayotomiwa Idowu</p>
            <h2>FINANCIAL ANALYST</h2>
          </div>
          <div className="team_member">
            <img src={rabiu} alt="team" />
            <p>Rabiu Adamu</p>
            <h2>LOGISTICS & TRADE MANAGER</h2>
          </div>
          <div className="team_member">
            <img src={chuka} alt="team" />
            <p>Chukwuka Eluchie</p>
            <h2>PRODUCT MANAGER</h2>
          </div>
          <div className="team_member">
            <img src={Adeola} alt="team" />
            <p>Adeola Afolayan</p>
            <h2>PRODUCTION GROWTH AND MARKETING ANALYST</h2>
          </div>
          <div className="team_member">
            <img src={sammy} alt="team" />
            <p>Samuel Ilesanmi</p>
            <h2>QUALITY ASSURANCE (SOFTWARE TESTER)</h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamMembers;
