import React from "react";
import CardSlider from "../slider/Slider";
import "./Latest.scss";

const Related = () => {
  return (
    <div className='latest-wrapper'>
      <div className='section'>
        <h1 className='section-header'>Related</h1>
        <p className='links'>
          See all{" "}
          <svg
            width='8'
            height='12'
            viewBox='0 0 8 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M0.589966 10.59L5.16997 6L0.589966 1.41L1.99997 0L7.99997 6L1.99997 12L0.589966 10.59Z'
              fill='#717783'
            />
          </svg>
        </p>
      </div>

      <div className='latest-cards'>
        <CardSlider />
      </div>
    </div>
  );
};

export default Related;
