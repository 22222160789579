import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/footer/Footer";
// import NavBar from "../../components/NavBar/NavBar";
import Referral from "../../components/ReferralPage/Referral";
import TopNav from "../../components/TopNav/TopNav";

const ReferralPage = () => {
  return (
    <div>
      <Helmet>
        <title>TradeBuza - Referral </title>
        <meta
          name='description'
          content='At TradeBuza, we take pride in building strong relationships with our customers, giving them a positive experience they will want to share with others.'
        />
        <link rel='canonical' href='https://www.tradebuza.com/referral' />
      </Helmet>
      {/* <NavBar /> */}
      <TopNav />
      <Referral />
      <Footer />
    </div>
  );
};

export default ReferralPage;
