import React, { useState } from "react";
import { Form, Formik } from "formik";
import "./AdminLogin.scss";
import { useDispatch, useSelector } from "react-redux";
import { LOGIN_FAIL, LOGIN_SUCCESS } from "../../../../redux/actions/Auth";
import { TRADEBUZA_BLOG_URL } from "../../../../configs/api";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AdminLogin = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const Auth = useSelector((state) => state.Auth);
  console.log("Authlogin", Auth);

  if (Auth?.isAuthenticated) {
    navigate("/admin-dashboard");
  }

  return (
    <div className='form__wrapper admin-login'>
      <div className='form__title'>
        <h2>Welcome Admin</h2>
      </div>

      <div className='form__body'>
        <Formik
          initialValues={{ email: "", password: "" }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Email is required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            if (!values.password) {
              errors.password = "Password is required";
            } else if (values.password.length < 8) {
              errors.password = "Password must be at least 8 characters";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setIsLoading(true);
            const body = {
              email: values.email,
              password: values.password,
            };

            try {
              const res = await axios.post(`${TRADEBUZA_BLOG_URL}/auth/login`, body);
              dispatch({
                type: LOGIN_SUCCESS,
                payload: res?.data,
              });
              navigate("/admin-dashboard");
              console.log("resss", res);
            } catch (error) {
              dispatch({
                type: LOGIN_FAIL,
              });
              toast.error("Invalid email or password");
              setIsLoading(false);
              return error;
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
            dirty,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className='form_input_wrapper'>
                <label>Email Address</label>
                <input
                  type='text'
                  name='email'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  placeholder='Email'
                  className={
                    errors.email && touched.email ? "input-error" : null
                  }
                />
                {errors.email && touched.email && (
                  <span className='error'>{errors.email}</span>
                )}
              </div>
              <div className='form_input_wrapper password-input'>
                <label>Password</label>
                <input
                  type='password'
                  name='password'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  placeholder='Password'
                  className={
                    errors.password && touched.password ? "input-error" : null
                  }
                />
                {errors.password && touched.password && (
                  <span className='error'>{errors.password}</span>
                )}
              </div>

              <div className='login-btn'>
                <button type='submit' disabled={!dirty}>
                  {isLoading ? "Loading..." : "Login"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AdminLogin;
