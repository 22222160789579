import React from "react";
import { Link } from "react-router-dom";
import SecondNav from "../../components/secondNav/SecondNav";
import "./AgroWeb.scss";
import bannerImage from "../../assets/img/banner-image.svg";
import Icon from "../../assets/img/Icon.svg";
import Icon1 from "../../assets/img/Icon (1).svg";
import Icon3 from "../../assets/img/Icon (3).svg";
import farmerImage from "../../assets/img/farmer-image.svg";
import Footer from "../../components/footer/Footer";
import { Helmet } from "react-helmet";

const AgroWeb = () => {
  return (
    <>
      <Helmet>
        <title>TradeBuza - Agro</title>
        <meta
          name='description'
          content='Cloud-based Web and Mobile Farm Management Technology.'
        />
        <link rel='canonical' href='https://www.tradebuza.com/agro' />
      </Helmet>
      <div className='page__wrapper'>
        <div className='rt-bg'></div>
        <SecondNav
          service='/agro-services'
          logIn='http://agro-demo.tradebuza.com'
        />
        <div className='page__container'>
          <div className='hero__wrapper'>
            <div className='hero__img__sm'>
              <img src={bannerImage} alt='img' srcSet='' />
            </div>
            <div className='hero__text'>
              <h4 className='banner-title'>
                {/* TradeBuza <span className='underline'>AGRO</span> */}
                TradeBuza Agro
              </h4>
              <h4 className='banner-heading'>
                Cloud-based Web and Mobile Farm Management
              </h4>
              <p className='banner-subtitle'>
                Monitor and track the activities of outgrower schemes and
                aggregators from preproduction through harvest.
              </p>
              <Link to='/get-started'>
                <button className='banner-button'>Schedule a Demo</button>
              </Link>
            </div>
            <div className='hero__img'>
              <img src={bannerImage} alt='img' srcSet='' />
            </div>
          </div>

          <div className='banner-cards'>
            <div className='card'>
              <img src={Icon} alt='' srcSet='' />
              <h4 className='card-title'>Near Real-Time Visibility</h4>
              <p className='card-subtitle'>
                View field activities captured digitally by field agents in
                real-time.
              </p>
            </div>
            <div className='card'>
              <img src={Icon1} alt='' srcSet='' />
              <h4 className='card-title'>Payment Services</h4>
              <p className='card-subtitle'>
                Automate your payments and transactions processing within the
                Outgrower scheme, and with Input suppliers, Agents & Farmers.
              </p>
            </div>

            <div className='card'>
              <img src={Icon} alt='' srcSet='' />
              <h4 className='card-title'>Data Management</h4>
              <p className='card-subtitle'>
                View and audit farmers, field staff, and farm-lot data while
                eliminating bulky paper-based processes.
              </p>
            </div>

            <div className='card'>
              <img src={Icon1} alt='' srcSet='' />
              <h4 className='card-title'>Simplified Reporting</h4>
              <p className='card-subtitle'>
                Identify all farms behind schedule, reducing travel costs and
                increasing efficiency.
              </p>
            </div>
            <div className='card'>
              <img src={Icon} alt='' srcSet='' />
              <h4 className='card-title'>Commodities Trade</h4>
              <p className='card-subtitle'>
                We facilitate commodities trade to a network of
                buyers/off-takers.
              </p>
            </div>

            <div className='card'>
              <img src={Icon1} alt='' srcSet='' />
              <h4 className='card-title'>Harvest & Collections</h4>
              <p className='card-subtitle'>Tracking per farmer.</p>
            </div>
          </div>

          <div id='api'>
            <div className='api-container'>
              <div className='api__img'>
                <img src={farmerImage} alt='' srcSet='' />
              </div>
              <div className='onboard'>
                <img src={Icon3} alt='' srcSet='' />
                <h4 className='banner-headings'>
                  Onboard users, investors and advisors as you grow
                </h4>
                <p className='banner-subtitle'>
                  Our infrastructure caters to a broad spectrum of clients
                  ranging from large growers, multi-farm corporations, food
                  conglomerates, ag-financing and insurance companies, to
                  governments and Development Finance Institutions who require
                  key last mile operational data to engage these agribusiness.
                </p>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default AgroWeb;
