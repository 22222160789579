import React, { Component } from "react";
import "./Partners.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import google from "../../assets/img/google.png";
import agbe from "../../assets/img/agbe.png";
import launchpad from "../../assets/img/launchpad.png";
import propoor from "../../assets/img/propoor.png";
import itanna from "../../assets/img/itanna.png";
import riby from "../../assets/img/riby-logo.png";
import honeywell from "../../assets/img/honeywell.png";
import tecniseeds from "../../assets/img/tecniseeds.jpg";
import jica from "../../assets/img/jica.png";
import techdisti from "../../assets/img/techdisti.jpg";
import aca from "../../assets/img/aca.jpg";
import aku from "../../assets/img/aku.png";
import agroeknor from "../../assets/img/agroeknor.jpg";
import fao from "../../assets/img/fao.png";
import greenhills from "../../assets/img/greenhills.png";
import topan from "../../assets/img/topan.png";

export default class Partners extends Component {
  render() {
    // const settings = {
    //   dots: true,
    //   infinite: true,
    //   slidesToShow: 5,
    //   slidesToScroll: 1,
    //   autoplay: true,
    //   speed: 1000,
    //   autoplaySpeed: 3000,
    //   cssEase: "linear"
    // };

    var settings = {
      dots: true,
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "60px",
      slidesToShow: 4,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 3000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <section className='partners'>
        <div>
          <h4 className='partner-title'>Our Partners</h4>
          <p className='partner-subtitle'>Brands we have worked with</p>
        </div>
        <div className='partners-container'>
          <Slider {...settings}>
            <div className='image-container'>
              <a
                href='http://google.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                <span className='helper'>
                  <img src={google} alt='google logo' />
                </span>
              </a>
            </div>
            <div className='image-container'>
              <a
                href='https://transwealth.riby.ng/home'
                target='_blank'
                rel='noopener noreferrer'
              >
                <span className='helper'>
                  <img src={agbe} alt='agbe logo' />
                </span>
              </a>
            </div>
            <div className='image-container'>
              <a
                href='https://developers.google.com/community/accelerators'
                target='_blank'
                rel='noopener noreferrer'
              >
                <span className='helper'>
                  <img src={launchpad} alt='launchpad logo' />
                </span>
              </a>
            </div>
            <div className='image-container'>
              <a
                href='http://propoorgroup.ng/index.html'
                target='_blank'
                rel='noopener noreferrer'
              >
                <span className='helper'>
                  <img src={propoor} alt='propoor logo' />
                </span>
              </a>
            </div>

            <div className='image-container'>
              <a href='https://itanna.co' target='_blank' rel="noreferrer">
                <span className='helper'>
                  <img src={itanna} alt='itanna logo' />
                </span>
              </a>
            </div>
            <div className='image-container'>
              <a
                href='http://riby.me'
                target='_blank'
                rel='noopener noreferrer'
              >
                <span className='helper'>
                  <img src={riby} alt='riby logo' />
                </span>
              </a>
            </div>
            <div className='image-container'>
              <a
                href='https://www.honeywellgroup.com/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <span className='helper'>
                  <img src={honeywell} alt='honeywell logo' />
                </span>
              </a>
            </div>
            <div className='image-container'>
              <a
                href='http://tecniseeds.com/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <span className='helper'>
                  <img src={tecniseeds} alt='tecniseeds logo' />
                </span>
              </a>
            </div>
            <div className='image-container'>
              <span className='helper'>
                <img src={jica} alt='jica logo' className='mita' />
              </span>
            </div>
            <div className='image-container'>
              <span className='helper'>
                <img src={techdisti} alt='techdisti logo' className='mita' />
              </span>
            </div>
            <div className='image-container'>
              <a
                href='https://www.acagp.com/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <span className='helper'>
                  <img src={aca} alt='aca logo' className='mita' />
                </span>
              </a>
            </div>
            <div className='image-container'>
              <a
                href='https://agroeknor.com/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <span className='helper'>
                  <img src={agroeknor} alt='agroeknor logo' className='mita eknor' />
                </span>
              </a>
            </div>
            <div className='image-container'>
              <a
                href='https://www.fao.org/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <span className='helper'>
                  <img src={fao} alt='fao logo' className='mita fao' />
                </span>
              </a>
            </div>
            <div className='image-container'>
              <a
                href='https://akufintech.com/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <span className='helper'>
                  <img src={aku} alt='aku logo' className='mita' />
                </span>
              </a>
            </div>
            <div className='image-container'>
              <a
                href='https://greenhillsfarmstead.com/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <span className='helper'>
                  <img src={greenhills} alt='greenhiils logo' className='mita greenhills' />
                </span>
              </a>
            </div>
            <div className='image-container'>
              <a
                href='https://www.topanigeria.com/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <span className='helper'>
                  <img src={topan} alt='topan logo' className='mita topan' />
                </span>
              </a>
            </div>
          </Slider>
        </div>
      </section>
    );
  }
}
