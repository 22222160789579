import React from "react";
import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import AdminSideBar from "../../../components/adminSideBar/AdminSideBar";
// import NavBar from "../../../components/NavBar/NavBar";
import TopNav from "../../../components/TopNav/TopNav";
import "./AdminDashboardLayout.scss";

const AdminDashboardLayout = (props) => {
  // const navigate = useNavigate();
  const Auth = useSelector((state) => state.Auth);
  console.log("Auth", Auth);

  // if (Auth?.isAuthenticated === null) {
  //   navigate("/admin");
  // }


  return (
    <div className='dasboard_wrapper'>
      <div className='dashboard__top__nav'>
        {/* <NavBar /> */}
        <TopNav />
      </div>

      <div className='wrap'>
        <div className='left'>
          <AdminSideBar />
        </div>

        <div className='right'>
          <div>{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardLayout;
