import React from "react";
import { Link } from "react-router-dom";
import SecondNav from "../../components/secondNav/SecondNav";
import "./AgroApiWeb.scss";
import bannerImage from "../../assets/img/banner-image.svg";
import Icon from "../../assets/img/Icon.svg";
import Icon1 from "../../assets/img/Icon (1).svg";
// import Icon3 from "../../assets/img/Icon (3).svg";
import Footer from "../../components/footer/Footer";
import { Helmet } from "react-helmet";
// import Carbon from "../../assets/img/carbon.png";

const AgroApiWeb = () => {
  return (
    <>
      <Helmet>
        <title>TradeBuza - AgroAPI</title>
        <meta
          name='description'
          content='Remote Sensing & Satellite Imagery API for Agriculture.'
        />
        <link rel='canonical' href='https://www.tradebuza.com/agro-api' />
      </Helmet>
      <div className='page__wrapper'>
        <div className='rt-bg'></div>
        <SecondNav service='/agro-services' logIn='http://tie.tradebuza.com/' />
        <div className='page__container'>
          <div className='hero__wrapper'>
            <div className='hero__img__sm'>
              <img src={bannerImage} alt='img' srcSet='' />
            </div>
            <div className='hero__text'>
              {/* <h4 className='banner-title'>
              TRADEBUZA <span className='underline'>AGRO</span>
            </h4> */}
              <h4 className='banner-title'>TradeBuza AgroAPI</h4>
              <h4 className='banner-heading'>
                Remote Sensing & Satellite imagery API for Agriculture
              </h4>
              <p className='banner-subtitle'>
                Leverage our API infrastructure to validate your operational and
                agriculture financing processes.
              </p>
              <Link to='/get-started'>
                <button className='banner-button'>Schedule a Demo</button>
              </Link>
            </div>
            <div className='hero__img'>
              <img src={bannerImage} alt='img' srcSet='' />
            </div>
          </div>

          <div className='banner-cards'>
            <div className='card'>
              <img src={Icon} alt='' srcSet='' />
              <h4 className='card-title'> Farm Monitoring</h4>
              <p className='card-subtitle'>
                Receive standardized and aggregated real-time imagery from major
                satellite providers. Monitor your field health evolution over
                time.
              </p>
            </div>
            <div className='card'>
              <img src={Icon1} alt='' srcSet='' />
              <h4 className='card-title'>Farm Validation</h4>
              <p className='card-subtitle'>
                Get consistent and instant validation checks for all your
                preferred farmlands, with full datasets and summaries based on
                GPS coordinates and rendered maps.
              </p>
            </div>

            <div className='card'>
              <img src={Icon} alt='' srcSet='' />
              <h4 className='card-title'>Payment Services</h4>
              <p className='card-subtitle'>
                Disburse loans, salaries, and sales contributions in an easy and
                organized manner
              </p>
            </div>
          </div>

          {/* <div id='api'>
          <div className='api-container'>
            <div className='api__img'>
              <img src={Carbon} alt='' srcSet='' />
            </div>
            <div className='onboard'>
              <img src={Icon3} alt='' srcSet='' />
              <h4 className='banner-headings'>
                Onboard users, investors and advisors as you grow
              </h4>
              <p className='banner-subtitle'>
                Our infrastructure caters to a broad spectrum of clients ranging
                from large growers, multi-farm corporations, food conglomerates,
                ag-financing and insurance companies, to governments and
                Development Finance Institutions who require key last mile
                operational data to engage these agribusiness.
              </p>
            </div>
          </div>
        </div> */}
        </div>

        <Footer />
      </div>
    </>
  );
};

export default AgroApiWeb;
