import React, { useEffect } from "react";
import "./BlogDetails.scss";
// import img from "../../../assets/img/blog/blog1.png";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBlogById } from "../../../redux/actions/Auth";
import moment from "moment";

const BlogDetails = () => {
  const dispatch = useDispatch();
  const Auth = useSelector((state) => state.Auth);
  const { id } = useParams();
  console.log("Auth.blog", Auth.blog);

  useEffect(() => {
    dispatch(getBlogById(id));
  }, [dispatch, id]);

  return (
    <div className='blog-details'>
      <div className='link-title'>
        <p>
          <Link to='/blog'>
            <span className="">Blog </span>
          </Link>{" "}
          / Post
        </p>
      </div>

      <div className='blog-title'>
        {/* <div>
          <p className='blog-title-name'>Lateef Jakande</p>
        </div> */}
        <p className='blog-title-date'>
          Posted {moment(Auth.blog?.created_at).format("DD MMMM, YYYY")}
        </p>

        <h2 className='blog-title-header'>{Auth.blog?.title}</h2>
      </div>

      <div className='blog-img1'>
        <img src={Auth.blog?.cover} alt='' />
      </div>

      <div className='blog-text'>
        <div dangerouslySetInnerHTML={{ __html: Auth?.blog?.content }}></div>

        {/* <div>
            <p>WRITTEN BY</p>
            <p>Otunbade Aminat</p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam.{" "}
            </p>
          </div> */}
      </div>
    </div>
  );
};

export default BlogDetails;
