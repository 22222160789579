import React from "react";
import "./Homepage.scss";
import Footer from "../../components/footer/Footer";
import ScheduleDemo from "../../components/scheduleDemo/ScheduleDemo";
import Partners from "../../components/partners/Partners";
import WhoWeAre from "../../components/whoWeAre/WhoWeAre";
import Banner from "../../components/banner/Banner";
import Products from "../../components/products/Products";
import TopNav from "../../components/TopNav/TopNav";
// import OptOut from "../../components/optOut/OptOut";

const HomePage = () => {
  return (
    <div>
      <TopNav />
      <main className="home-page">
        <Banner />
        <WhoWeAre />
        <Products />
        <Partners />
        <ScheduleDemo />
        {/* <OptOut /> */}
      </main>
      <Footer />
    </div>
  );
};

export default HomePage;
