import React from "react";
import { Helmet } from "react-helmet";
// import BlogFooter from "../../components/blog/blogFooter/BlogFooter";
import BlogPost from "../../components/blog/blogPost/BlogPost";
import Footer from "../../components/footer/Footer";
import TopNav from "../../components/TopNav/TopNav";
import "./Blogs.scss";

const BlogPosts = () => {
  return (
    <>
      <Helmet>
        <title>TradeBuza - Blog</title>
        <meta
          name='description'
          content='Articles on Agritech, industry insights, company news, and more.'
        />
        <link rel='canonical' href='https://www.tradebuza.com/blog' />
      </Helmet>
      <TopNav />
      <div className='blog-wrapper'>
        <BlogPost />
        {/* <BlogFooter /> */}
        <Footer />
      </div>
    </>
  );
};

export default BlogPosts;
