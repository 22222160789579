import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./CategoryContents.scss";
import { listBlogs, listCategories } from "../../../../redux/actions/Auth";
import { Link } from "react-router-dom";
import moment from "moment";

const CategoryContents = () => {
  const dispatch = useDispatch();
  const Auth = useSelector((state) => state.Auth);
  const blogsLoading = Auth.blogsLoading;
  const blogs = Auth.blogs;
  const categories = Auth.categories;
  const [catId, setCatId] = useState(1);
  const [categories1, setCategories1] = useState(true);

  const handleCategories1 = (id) => {
    setCategories1(true);
    setCatId(id);
  };

  useEffect(() => {
    dispatch(listBlogs());
    dispatch(listCategories());
  }, [dispatch]);

  useEffect(() => {
    setCatId(categories[0]?.id);
  }, [categories]);

  return (
    <div className='categories-wrapper'>
      <div className='categories-header'>
        <ul>
          {categories.map((category) => (
            <li key={category.id}>
              <span
                className={
                  categories1 && category.id === catId
                    ? "categories-btn active"
                    : "categories-btn"
                }
                onClick={() => handleCategories1(category.id)}
              >
                {category?.attributes?.title}
              </span>
            </li>
          ))}
        </ul>
      </div>

      <div className='categories-content'>
        {blogsLoading ? (
          <p>Loading...</p>
        ) : (
          <div className='categories-content-1'>
            {blogs &&
              blogs
                .filter((blog) => blog?.included?.category?.id === catId)
                .slice(-3)
                .map((blog) => (
                  <div key={blog.id} className='categories-content-1-item'>
                    <div className='categories-content-1-1'>
                      <Link to={`/blog/${blog?.attributes?.slug}`}>
                        <img src={blog?.attributes?.cover} alt='img' />
                        <div className='content-msg'>
                          <h1>{blog?.attributes?.title}</h1>
                          <p>
                            {moment(blog?.attributes?.created_at).format(
                              "DD MMMM, YYYY"
                            )}
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryContents;
