import React from "react";
import "./TeamPage.scss";
import Footer from "../../components/footer/Footer";
// import NavBar from "../../components/NavBar/NavBar";
import ScheduleDemo from "../../components/scheduleDemo/ScheduleDemo";
import Partners from "../../components/partners/Partners";
import TeamMembers from "../../components/teamMembers/TeamMembers";
import TopNav from "../../components/TopNav/TopNav";
import { Helmet } from "react-helmet";

const TeamPage = () => {
  return (
    <div>
      <Helmet>
        <title>TradeBuza - Team </title>
        <meta
          name='description'
          content='We are a diverse, close-knit team with years of experience across different industries.'
        />
        <link rel='canonical' href='https://www.tradebuza.com/referral' />
      </Helmet>
      {/* <NavBar /> */}
      <TopNav />
      <main className='team-page'>
        <TeamMembers />
        <Partners />
        <ScheduleDemo />
      </main>
      <Footer />
    </div>
  );
};

export default TeamPage;
