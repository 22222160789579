import React from "react";
import { Link } from "react-router-dom";
import "./Categories.scss";
import CategoryContents from "./categoriesContents/CategoryContents";

const Categories = () => {
  return (
    <div className='category-wrapper'>
      <div className='section'>
        <h1 className='section-header'>All Categories</h1>
        <Link to='/categories' className='links'>
          See all{" "}
          <svg
            width='8'
            height='12'
            viewBox='0 0 8 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M0.589966 10.59L5.16997 6L0.589966 1.41L1.99997 0L7.99997 6L1.99997 12L0.589966 10.59Z'
              fill='#717783'
            />
          </svg>
        </Link>
      </div>

      <CategoryContents />
    </div>
  );
};

export default Categories;
