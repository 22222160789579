import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import "./OptOutHero.scss";
import axios from "axios";
import { AGRO_BASE_URL } from "../../configs/api";
// import { height } from "@fortawesome/free-regular-svg-icons/faAddressBook";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "69%",
    background: "#e5e5e5",
    borderRadius: "8px",
    padding: "40px 30px",
  },
};

const OptOutHero = () => {
  const [isLoading, SetIsLoading] = useState(false);
  const [modalIsOpen, SetModalIsOpen] = useState(false);
  const [finalModalIsOpen, SetFinalModalIsOpen] = useState(false);

  const openModal = () => {
    SetModalIsOpen(true);
  };

  const closeModal = () => {
    SetModalIsOpen(false);
  };

  const closeFinalModal = () => {
    SetFinalModalIsOpen(false);
  };

  const fullname = document.getElementById("name");
  const companyname = document.getElementById("company_name");
  console.log(fullname);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const registerOptions = {
    name: {
      required: "Enter name",
    },
    email: {
      required: "Enter email",
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: "Invalid email",
      },
    },
    company_name: {
      required: "Enter company name",
    },
  };

  // on form submit
  const handleSubmitForm = async (data) => {
    SetIsLoading(true);

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.post(
        `${AGRO_BASE_URL}/submit/optout/requests`,
        {
          name: data.name,
          email: data.email,
          company_name: data.company_name,
        },
        config
      );
      console.log("response", response);
      SetModalIsOpen(false);
      SetFinalModalIsOpen(true);
      reset();
      // window.open("https://www.tradebuza.com/");
    } catch (error) {}
  };

  return (
    <div className="referral">
      <header className="opt_cta1">
        <div className="opt_cta1__wrapper">
          <div className="opt_cta1__wrapper__header">
            <h1>Opt-Out Request</h1>
            <p>
              By submitting this opt-out request, I understand that my personal
              data will no longer be used by TradeBuza Technologies Limited for
              any purposes and will be permanrntly removed from your database.
              <br />
              Please provide your contact details below:
            </p>
          </div>

          <div className="opt_cta1__form_wrapper">
            <form
              id="opt_form"
              className="opt_cta1__form"
              onSubmit={handleSubmit(openModal)}
            >
              <div className="field1 form-input-group">
                <label htmlFor="name" className="form-input-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-input"
                  id="name"
                  placeholder="Client's full name"
                  {...register("name", registerOptions.name)}
                />
              </div>
              <p className="error-message">
                {errors.name && errors.name.message}
              </p>

              <div className="field2 form-input-group">
                <label htmlFor="company_name" className="form-input-label">
                  Company Name
                </label>
                <input
                  type="text"
                  className="form-input"
                  id="company_name"
                  placeholder="Client's company full name"
                  {...register("company_name", registerOptions.company_name)}
                />
              </div>
              <p className="error-message">
                {errors.company_name && errors.company_name.message}
              </p>

              <div className="field3 form-input-group">
                <label htmlFor="email" className="form-input-label">
                  Email Address
                </label>
                <input
                  type="text"
                  className="form-input"
                  id="email"
                  placeholder="Client's email address"
                  {...register("email", registerOptions.email)}
                />
              </div>
              <p className="error-message">
                {errors.email && errors.email.message}
              </p>

              <div className="field4 form-input-group">
                <button className="form-input button" onSubmit={openModal}>
                  Submit
                </button>
                <Modal
                  isOpen={modalIsOpen}
                  // onRequestClose={closeModal}
                  style={customStyles}
                  contentLabel="Opt-Out Modal"
                >
                  <div className="opt_modal_wrapper">
                    <h1
                      style={{
                        fontSize: "20px",
                        textAlign: "center",
                        padding: "10px 30px",
                      }}
                    >
                      Opt-Out Request
                    </h1>
                    <p
                      style={{
                        fontSize: "14px",
                        textAlign: "left",
                        padding: "10px 30px",
                      }}
                    >
                      I, <b>{fullname?.value}</b> representing{" "}
                      <b>{companyname?.value}</b>, hereby request to opt-out of
                      having my personal data stored in the database of{" "}
                      <b>TradeBuza</b>. I understand that by opting out, I may
                      not be able to access certain services or benefits
                      provided by <b>TradeBuza</b>.
                    </p>
                    <div
                      style={{
                        background: "white",
                        borderRadius: "6px",
                        padding: "40px 30px 90px 30px",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "14px",
                          textAlign: "left",
                          paddingBottom: "10px",
                        }}
                      >
                        Are you sure the details are correct and you will like
                        to proceed?
                      </p>

                      <button
                        style={{
                          fontSize: "15px",
                          padding: "8px",
                          background: "#31b5a7",
                          color: "white",
                          fontFamily: "SF Pro Text",
                          float: "right",
                          lineHeight: "20px",
                          letterSpacing: "0px",
                          textAlign: "center",
                          border: "none",
                          borderRadius: "4px",
                        }}
                        onClick={handleSubmit(handleSubmitForm)}
                      >
                        {isLoading ? "Loading..." : "Yes, Confirm"}
                      </button>
                      <button
                        style={{
                          fontSize: "15px",
                          marginRight: "10px",
                          padding: "8px",
                          float: "right",

                          color: "#31b5a7",
                          fontFamily: "SF Pro Text",
                          lineHeight: "20px",
                          letterSpacing: "0px",
                          textAlign: "center",
                          border: "none",
                        }}
                        onClick={closeModal}
                      >
                        No, go back
                      </button>
                    </div>
                  </div>
                </Modal>
              </div>
            </form>
          </div>

          <div className="opt_cta2__wrapper__header">
            <h1>Instructions:</h1>
            <p>
              Please fill out the form fields above and click the submit button
              to request opt-out.
              <br />
              Upon submitting your request, we will process it within 30 days
              and confirm the removal of your data from our database.
              <br />
              Thank you for your cooperation.
              <br />
              <br />
              Sincerely,
              <br />
              IT Department,
              <br />
              TradeBuza
            </p>
          </div>
        </div>
      </header>

      <Modal
        isOpen={finalModalIsOpen}
        // onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Opt-Out Modal"
      >
        <div className="opt_modal_wrapper">
          <h1
            style={{
              fontSize: "20px",
              textAlign: "center",
              padding: "10px 20px",
            }}
          >
            Opt-Out Request
          </h1>
          <p
            style={{
              fontSize: "14px",
              textAlign: "center",
              paddingBottom: "10px",
            }}
          >
            Thank you for providing your details, your opt-out request is
            successful
          </p>
          <button
            style={{
              fontSize: "15px",
              padding: "10px",
              background: "#31b5a7",
              color: "white",
              fontFamily: "SF Pro Text",
              float: "right",
              lineHeight: "20px",
              letterSpacing: "0px",
              textAlign: "center",
              border: "none",
              borderRadius: "4px",
            }}
            onClick={closeFinalModal}
          >
            Done
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default OptOutHero;
