import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { listBlogs } from "../../../redux/actions/Auth";
import CardSlider from "../slider/Slider";
import "./Latest.scss";

const Latest = () => {
  const dispatch = useDispatch();
  const Auth = useSelector((state) => state.Auth);

  useEffect(() => {
    dispatch(listBlogs());
  }, [dispatch]);

  console.log("AuthBlogs", Auth);
  return (
    <div className='latest-wrapper'>
      <div className='section'>
        <h1 className='section-header'>Latest</h1>
        <Link to="/all-blogs" className='section-link'>
          <p className='links'>
            See all{" "}
            <svg
              width='8'
              height='12'
              viewBox='0 0 8 12'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M0.589966 10.59L5.16997 6L0.589966 1.41L1.99997 0L7.99997 6L1.99997 12L0.589966 10.59Z'
                fill='#717783'
              />
            </svg>
          </p>
        </Link>
      </div>

      <div className='latest-cards'>
        <CardSlider />
      </div>
    </div>
  );
};

export default Latest;
