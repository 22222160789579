import React, { useState } from "react";
import "./Tag.scss";
import { toast } from "react-toastify";
import axios from "axios";
import { TRADEBUZA_BLOG_URL } from "../../../configs/api";


const Tag = () => {
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const token = localStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const body = {
      title: value,
    };
    try {
      const res = await axios.post(`${TRADEBUZA_BLOG_URL}/tags`, body, config);
      toast.success("Category created Successful");
      console.log("res", res);
      setIsLoading(false);
    } catch (error) {
      toast.error("Cannot add category");
      setIsLoading(false);
      return error;
    }
  };

  return (
    <div className='add-blog-wrapper'>
      <h1 className='heading'>Add Tag</h1>
      <form className='form'>
        <div>
        <div className='form-group'>
          <label className=''>Title</label>
          <input
            type='text'
            className='form-control'
            placeholder='Enter Title'
            name='title'
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        <div className='form-group'>
          <button className='cta' type='subit' onClick={handleSubmit}>
          {isLoading ? "Loading..." : "PUBLISH"}
          </button>
        </div>
        </div>
      </form>
    </div>
  );
};

export default Tag;
