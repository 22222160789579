import React from "react";
import Modal from "react-modal";
import "./ModalMessage.scss";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "403px",
    // height: "380px",
    background: "#FFFFFF",
    borderRadius: "8px",
  },
};

const ModalMessage = ({ handleModalClose, msg }) => {
  // let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(true);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
    handleModalClose();
  }

  return (
    <div>
      <p onClick={openModal}></p>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='Example Modal'
        ariaHideApp={false}
      >
        <div className='modal__wrapper'>
          {/* <h1>Thank you</h1> */}

          <svg
            width='49'
            height='48'
            viewBox='0 0 49 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g clip-path='url(#clip0_157_3243)'>
              <path
                d='M43.5691 32.4627C42.6424 31.116 43.1598 29.1693 42.9291 27.02C42.6918 23.5133 41.1851 20.116 38.2624 17.3987L28.3078 8.14267C28.3078 8.14267 26.3464 6.31734 24.5198 8.28C22.6958 10.2413 24.6584 12.0667 24.6584 12.0667L28.8638 15.9787L32.5091 19.368C32.5091 19.368 31.5958 20.3493 27.5611 16.5973L13.9344 3.92267C13.9344 3.92267 11.9704 2.09867 10.1451 4.06134C8.32111 6.02267 10.2824 7.84801 10.2824 7.84801L23.1678 19.8333C23.1398 19.8067 22.1931 20.756 22.1758 20.74L8.38911 7.91734C8.38911 7.91734 6.42644 6.092 4.60111 8.05467C2.77577 10.0173 4.73844 11.8413 4.73844 11.8413L18.5264 24.6667C18.5424 24.6827 17.6544 25.6853 17.6811 25.7107L5.78777 14.6467C5.78777 14.6467 3.82511 12.8213 1.99977 14.784C0.174441 16.7467 2.13711 18.572 2.13711 18.572L14.7824 30.332C14.8984 30.44 14.1158 31.5427 14.2611 31.6773L5.14911 23.2027C5.14911 23.2027 3.18644 21.3773 1.36111 23.3413C-0.464225 25.304 1.49844 27.1293 1.49844 27.1293L16.3611 40.9467C18.8424 43.2547 21.3904 44.4227 24.4624 44.8507C25.0878 44.9373 25.8878 44.9373 26.2158 44.9787C27.2518 45.1093 28.1611 45.4773 29.3784 46.224C30.9451 47.184 33.2958 47.584 34.6464 46.14L39.2518 41.2133L43.5038 36.664C44.8531 35.2213 44.6118 33.976 43.5691 32.4627V32.4627Z'
                fill='#EF9645'
              />
              <path
                d='M3.10776 46.7013C2.76643 46.7013 2.42509 46.5707 2.16509 46.3107C1.64376 45.7893 1.64376 44.9467 2.16509 44.4253L6.89976 39.6933C7.4211 39.172 8.26376 39.172 8.7851 39.6933C9.30643 40.2147 9.30643 41.0573 8.7851 41.5787L4.05043 46.312C3.79043 46.572 3.44909 46.7013 3.10776 46.7013V46.7013ZM12.1024 47.9307C11.9571 47.9307 11.8078 47.9067 11.6611 47.856C10.9664 47.6133 10.5998 46.8533 10.8438 46.1573L12.1384 42.46C12.3811 41.7653 13.1424 41.4 13.8371 41.6413C14.5318 41.8853 14.8984 42.6453 14.6544 43.34L13.3598 47.0373C13.1678 47.5867 12.6531 47.9307 12.1024 47.9307V47.9307ZM1.87976 37.708C1.33043 37.708 0.815762 37.3653 0.622428 36.8187C0.377095 36.124 0.741095 35.3627 1.43576 35.1173L4.76243 33.9427C5.45976 33.7027 6.21843 34.064 6.46376 34.756C6.7091 35.4507 6.3451 36.212 5.65043 36.4573L2.32376 37.632C2.17576 37.684 2.02643 37.708 1.87976 37.708V37.708ZM41.1598 8.68533C40.8184 8.68533 40.4771 8.55467 40.2171 8.29467C39.6958 7.77333 39.6958 6.92933 40.2171 6.408L44.9531 1.67467C45.4744 1.15467 46.3171 1.15467 46.8384 1.67467C47.3598 2.19467 47.3598 3.04 46.8384 3.56133L42.1024 8.29467C41.8424 8.556 41.5011 8.68533 41.1598 8.68533V8.68533ZM35.6078 6.42267C35.4624 6.42267 35.3131 6.39733 35.1678 6.34667C34.4731 6.104 34.1064 5.344 34.3491 4.648L35.6424 0.950667C35.8864 0.256 36.6451 -0.110666 37.3411 0.132001C38.0358 0.374667 38.4024 1.13467 38.1598 1.83067L36.8664 5.528C36.6744 6.07867 36.1584 6.42267 35.6078 6.42267V6.42267ZM43.7984 14.1213C43.2491 14.1213 42.7344 13.7787 42.5411 13.232C42.2958 12.5373 42.6611 11.776 43.3544 11.5307L46.6798 10.356C47.3758 10.1093 48.1358 10.476 48.3811 11.1693C48.6264 11.864 48.2611 12.6253 47.5678 12.8707L44.2424 14.0453C44.0944 14.0973 43.9451 14.1213 43.7984 14.1213V14.1213Z'
                fill='#FA743E'
              />
              <path
                d='M47.6868 31.7627C46.8054 30.3867 47.3881 28.4573 47.2308 26.3013C47.1108 22.7893 45.7201 19.3427 42.8908 16.528L33.2534 6.94267C33.2534 6.94267 31.3548 5.05334 29.4628 6.95334C27.5734 8.852 29.4734 10.7427 29.4734 10.7427L33.5454 14.7947L37.0734 18.304C37.0734 18.304 36.1268 19.2533 32.2214 15.368C28.3134 11.4813 19.0294 2.24134 19.0294 2.24134C19.0294 2.24134 17.1294 0.352004 15.2388 2.252C13.3481 4.15067 15.2481 6.04 15.2481 6.04L27.7228 18.4533C27.6961 18.4267 26.7174 19.3427 26.7001 19.3253L13.3534 6.04534C13.3534 6.04534 11.4534 4.15467 9.56276 6.05467C7.67343 7.95467 9.5721 9.844 9.5721 9.844L22.9201 23.1267C22.9361 23.1427 22.0134 24.1147 22.0401 24.1413L10.5268 12.684C10.5268 12.684 8.62677 10.7947 6.7361 12.6947C4.84543 14.5947 6.74543 16.4853 6.74543 16.4853L18.9868 28.6653C19.0988 28.776 18.2801 29.8533 18.4201 29.9933L9.59876 21.216C9.59876 21.216 7.69876 19.3267 5.8081 21.2267C3.91743 23.1267 5.81743 25.0173 5.81743 25.0173L20.2041 39.3267C22.6068 41.7173 25.1134 42.9693 28.1694 43.5013C28.7921 43.6093 29.5921 43.636 29.9174 43.688C30.9481 43.8533 31.8441 44.252 33.0374 45.04C34.5708 46.052 36.9068 46.5307 38.3054 45.1333L43.0748 40.3653L47.4774 35.9627C48.8761 34.5627 48.6774 33.3093 47.6868 31.7627V31.7627Z'
                fill='#FFDB5E'
              />
            </g>
            <defs>
              <clipPath id='clip0_157_3243'>
                <rect
                  width='48'
                  height='48'
                  fill='white'
                  transform='translate(0.5)'
                />
              </clipPath>
            </defs>
          </svg>

          <p>{msg}</p>

          <button onClick={closeModal}>
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ModalMessage;