import axios from "axios";
import { toast } from "react-toastify";
import { TRADEBUZA_BLOG_URL } from "../../configs/api";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const LIST_CATEGORIES_SUCCESS = "LIST_CATEGORIES_SUCCESS";

export const LIST_BLOGS_SUCCESS = "LIST_BLOGS_SUCCESS";

export const GET_BLOGS_SUCCESS = "GET_BLOGS_SUCCESS";

export const GET_BLOGS_BY_CATEGORY_SUCCESS = "GET_BLOGS_BY_CATEGORY_SUCCESS";

export const DELETE_BLOG_SUCCESS = "DELETE_BLOG_SUCCESS";

export const DELETE_BLOG_CATEGORY_SUCCESS = "DELETE_BLOG_CATEGORY_SUCCESS";

export const LOGOUT = "LOGOUT";

export const LOADING = "LOADING";

export const LOADING_BLOGS = "LOADING_BLOGS";

// LOGOUT
// eslint-disable-next-line
export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};

export const listCategories = () => async (dispatch) => {

  try {
    const res = await axios.get(`${TRADEBUZA_BLOG_URL}/categories`);
    dispatch({
      type: LIST_CATEGORIES_SUCCESS,
      payload: res.data?.result?.data,
    });
  } catch (error) {
    console.log(error);
  }
}

export const listBlogs = (pageNumber) => async (dispatch) => {
  dispatch({
    type: LOADING_BLOGS,
  });
  try {
    const res = await axios.get(`${TRADEBUZA_BLOG_URL}/posts?page=${pageNumber}`);
    dispatch({
      type: LIST_BLOGS_SUCCESS,
      payload: res.data?.result?.data,
    });
    console.log("blogjjdk", res.data?.result?.data);
  } catch (error) {
    dispatch({
      type: LOADING_BLOGS,
    });
    console.log(error);
  }
}

export const getBlogById = (id) => async (dispatch) => {

  try {
    const res = await axios.get(`${TRADEBUZA_BLOG_URL}/posts/${id}`);
    dispatch({
      type: GET_BLOGS_SUCCESS,
      payload: res.data?.result?.attributes,
    });
    console.log("single blog", res);
  } catch (error) {
    console.log(error);
  }
}

export const deleteBlog = (id) => async (dispatch) => {
  const token = localStorage.getItem("access_token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };


  try {
    const res = await axios.delete(`${TRADEBUZA_BLOG_URL}/posts/${id}`, config);
    dispatch({
      type: DELETE_BLOG_SUCCESS,
      payload: id,
    });
    toast.success("Blog deleted successfully");
    console.log("delete blog", res);
  } catch (error) {
    console.log(error);
    toast.error("Error deleting blog");
  }
}

