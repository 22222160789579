import React, { useState } from "react";
import { useForm } from "react-hook-form";
// import { Link } from "react-router-dom";
import "./Referral.scss";
import works1 from "../../assets/img/how-it-works1.svg";
import works2 from "../../assets/img/how-it-works2.svg";
import works3 from "../../assets/img/how-it-works3.svg";
// import docs from "../../assets/img/docs.png";
// import docs from "../../assets/img/docs-image.svg";
import docsImage from "../../assets/img/docs-image.png";
// import cto2 from "../../assets/img/cto2.png";
import cto2 from "../../assets/img/refer-image.svg";
// import copyIcon from "../../assets/img/copy.svg";
// import gmail from "../../assets/img/gmail.svg";
// import { FaFacebook, FaTwitter, FaLinkedin, FaWhatsapp } from "react-icons/fa";
import axios from "axios";
import ModalMessage from "../modal/ModalMessage";
import { TRADEBUZA_URL } from "../../configs/api";

const Referral = () => {
  const [proceedButton, SetProceedButton] = useState(true);
  const [submitButton, SetSubmitButton] = useState(false);
  const [enableButton, SetEnableButton] = useState(false);
  const [userStatus, SetUserStatus] = useState(false);
  const [registerUser, SetRegisterUser] = useState(false);
  const [isLoading, SetIsLoading] = useState(false);
  const [modalIsOpen, SetModalIsOpen] = useState(false);
  const [msg, setMsg] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const registerOptions = {
    name: {
      required: "Enter name",
    },
    email: {
      required: "Enter email",
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: "Invalid email",
      },
    },
    number: {
      required: "Enter phone number",
    },
    organisation: {
      required: "Enter organisation",
    },
    referree_code: {
      required: "Enter email",
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: "Invalid email",
      },
    },
    userStatus: {
      required: "Select one option",
    },
    userName: {
      required: "Enter name",
    },
    userEmail: {
      required: "Enter email",
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: "Invalid email",
      },
    },
    userNumber: {
      required: "Enter phone number",
    },
  };

  // on proceed button click
  const handleProceed = () => {
    SetUserStatus(!userStatus);
    SetProceedButton(false);
    SetSubmitButton(true);
  };

  // for radio button for existing user
  const handleNewUser = () => {
    SetRegisterUser(true);
    setMsg("");
  };
  const handleOldUser = () => {
    SetRegisterUser(false);
    SetEnableButton(true);
  };

  const handleModalClose = () => {
    SetModalIsOpen(false);
  };

  // on form submit
  const handleSubmitForm = async (data) => {
    // return;
    SetIsLoading(true);

    //add headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios
      .post(
        `${TRADEBUZA_URL}/inviteclient`,
        {
          name: data.name,
          email: data.email,
          phone: data.number,
          organisation: data.organisation,
          referree_code: data.referree_code,
          existing_user: data.userStatus,
          lead_name: data.userName,
          lead_email: data.referree_code,
          lead_phone: data.userNumber,
        },
        config
      )

      .then((response) => {
        console.log("response", response);
        SetIsLoading(false);
        SetModalIsOpen(true);
        reset({
          name: "",
          email: "",
          number: "",
          organisation: "",
          referree_code: "",
          userStatus: "",
          userName: "",
          userEmail: "",
          userNumber: "",
        });
        setMsg(
          "You have successfully referred a contact. You will earn upto 10% when we close the deal."
        );
      })
      .catch((error) => {
        SetIsLoading(false);
        console.log("error", error.response.data.error);
        setMsg(error.response.data.error);
        SetModalIsOpen(true);
      });
  };

  return (
    <div className='referral'>
      <header className='cta1'>
        <div className='cta1__wrapper'>
          <div className='content'>
            <div className='cta1__wrapper__header'>
              <h1>Welcome to our Refer and Earn program</h1>
              <p>
                {/* Kindly submit the information of your contacts in crop
                production, farmers' associations, outgrower schemes, ag-lending
                and financing, agri-insurance and other agri-businesses. */}
                At TradeBuza, we take pride in building strong relationships with our customers, giving them a positive experience they will want to share with others. And if you pass it on, and introduce new clients to us, we want to thank you!
              </p>
            </div>

            <form
              className='cta1__form'
              onSubmit={handleSubmit(handleSubmitForm)}
            >
              <p>Fill the form below</p>
              <div className='form-input-group'>
                <label htmlFor='name' className='form-input-label'>
                  Contact's Name
                </label>
                <input
                  type='text'
                  className='form-input'
                  id='name'
                  placeholder=''
                  {...register("name", registerOptions.name)}
                />
              </div>
              <p className='error-message'>
                {errors.name && errors.name.message}
              </p>

              <div className='form-input-group'>
                <label htmlFor='email' className='form-input-label'>
                  Contact's E-mail
                </label>
                <input
                  type='text'
                  className='form-input'
                  id='email'
                  placeholder=''
                  {...register("email", registerOptions.email)}
                />
              </div>
              <p className='error-message'>
                {errors.email && errors.email.message}
              </p>

              <div className='form-input-group'>
                <label htmlFor='number' className='form-input-label'>
                  Contact's Phone number
                </label>
                <input
                  type='tel'
                  className='form-input'
                  id='number'
                  placeholder=''
                  {...register("number", registerOptions.number)}
                />
              </div>
              <p className='error-message'>
                {errors.number && errors.number.message}
              </p>

              <div className='form-input-group'>
                <label
                  htmlFor='organisation'
                  className='form-input-label'
                >
                  Contact's Organisation
                </label>
                <input
                  type='text'
                  className='form-input'
                  id='organisation'
                  placeholder=''
                  {...register("organisation", registerOptions.organisation)}
                />
              </div>
              <p className='error-message'>
                {errors.organisation && errors.organisation.message}
              </p>

              <div className='form-input-group'>
                <label htmlFor='userEmail' className='form-input-label'>
                  Personal E-mail
                </label>
                <input
                  type='email'
                  className='form-input'
                  id='referree_code'
                  placeholder=''
                  {...register("referree_code", registerOptions.referree_code)}
                />
              </div>
              <p className='error-message'>
                {errors.referree_code && errors.referree_code.message}
              </p>

              {userStatus && (
                <div>
                  <div className='form-input-group user-status'>
                    <span className=''>Are you an existing user ?</span>
                    <span>
                      <input
                        className='form-input'
                        type='radio'
                        value='false'
                        {...register("userStatus")}
                        onClick={handleNewUser}
                      />
                      <label className='form-input-label'>No</label>

                      <input
                        className='form-input'
                        type='radio'
                        value='true'
                        {...register("userStatus")}
                        onClick={handleOldUser}
                      />
                      <label className='form-input-label'>Yes</label>
                    </span>
                  </div>
                  <p className='error-message'>
                    {errors.userStatus && errors.userStatus.message}
                  </p>
                </div>
              )}

              {registerUser && (
                <div className='register-form-inputs'>
                  <div className='form-body'>
                    <p>Kindly provide us with your personal details</p>
                  </div>
                  <div className='form-input-group'>
                    <label
                      htmlFor='userName'
                      className='form-input-label'
                    >
                      Name
                    </label>
                    <input
                      type='text'
                      className='form-input'
                      id='userName'
                      placeholder=''
                      {...register("userName", registerOptions.userName)}
                    />
                  </div>
                  <p className='error-message'>
                    {errors.userName && errors.userName.message}
                  </p>

                  {/* <div className='form-input-group'>
                    <label
                      htmlFor='userEmail'
                      className='form-input-label'
                      hidden
                    >
                      E-mail
                    </label>
                    <input
                      type='text'
                      className='form-input'
                      id='userEmail'
                      placeholder='E-mail'
                      {...register("userEmail", registerOptions.userEmail)}
                    />
                  </div>
                  <p className='error-message'>
                    {errors.userEmail && errors.userEmail.message}
                  </p> */}

                  <div className='form-input-group'>
                    <label
                      htmlFor='userNumber'
                      className='form-input-label'
                    >
                      Phone number
                    </label>
                    <input
                      type='tel'
                      className='form-input'
                      id='userNumber'
                      placeholder=''
                      {...register("userNumber", registerOptions.userNumber)}
                    />
                  </div>
                  <p className='error-message'>
                    {errors.userNumber && errors.userNumber.message}
                  </p>
                </div>
              )}

              {proceedButton && (
                <div className='form-input-group'>
                  <button className='form-input button' onClick={handleProceed}>
                    Proceed
                  </button>
                </div>
              )}

              {submitButton && (
                <div className='form-input-group'>
                  <button
                    className={`form-input button referral-btn  ${enableButton ? "enabled" : ""
                      }`}
                    // className="form-input button referral-btn"
                    type='submit'
                    onSubmit={handleSubmit(handleSubmitForm)}
                    disabled={enableButton ? "" : "disabled"}
                  >
                    {isLoading ? "Loading..." : "Submit"}
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </header>

      <section className='how-it-works'>
        <h3>How it works</h3>
        <div className='how-it-works__section'>
          <div className='how-it-works__card'>
            <div className='how-it-works__image'>
              <img src={works1} alt='how it works icon 1' />
            </div>
            <p className='how-it-works__body'>
              Fill in your information in the form above
            </p>
          </div>
          <div className='how-it-works__card'>
            <div className='how-it-works__image'>
              <img src={works2} alt='how it works icon 2' />
            </div>
            <p className='how-it-works__body'>
              Our team will reach out to you and follow up with timely updates.
            </p>
          </div>
          <div className='how-it-works__card'>
            <div className='how-it-works__image'>
              <img src={works3} alt='how it works icon 3' />
            </div>
            <p className='how-it-works__body'>
              We will credit your account with 10% of the closed deal on your qualified lead.
            </p>
          </div>
        </div>
      </section>

      <section className='docs'>
        {/* <img className="docs-bg" src={docs} alt="background" /> */}
        <div className='docs__wrapper'>
          <div className='docs__body'>
            <h3>Onboard users, investors and advisors as you grow</h3>
            <p>
              Our infrastructure caters to a broad spectrum of clients ranging
              from large growers, multi-farm corporations, food conglomerates,
              “ag-financing” and insurance companies, to governments and
              Development Finance Institutions who require key last mile
              operational data to engage these agribusiness.
            </p>
          </div>
          <div className='docs__image'>
            <img src={docsImage} alt='man cultivating corn in is farm.' />
          </div>
        </div>
      </section>

      <section className='cta2'>
        <div className='cta2__image'>
          <img src={cto2} alt='user images surrounding money stack.' />
        </div>
        <div className='cta2__body'>
          <h3>You can refer as many contacts as possible!</h3>
          <p>
            Now imagine referring 5 contacts and getting up to 10% commission off
            their deals? Yes! the more you refer, the more you earn!
          </p>
        </div>
      </section>

      {/* <section className="cta2">
        <div className="cta2__top">
          <div className="cta2__top__image">
            <img src={cto2} alt="user images surrounding money stack." />
          </div>
          <div className="cta2__top__body">
            <h3>You can refer as many contacts as possible!</h3>
            <p>
              Now imagine referring 5 contacts and getting 10% commission off
              their deals? Yes! the more you refer, the more you earn!
            </p>
          </div>
        </div>

        <div className="cta2__bottom">
          <p>
            All you need to do is click on the social icons to share your link
            or enter the details of your referral.
          </p>
          <div className="referral-input">
            <input
              type="text"
              name="referral"
              id="referral"
              placeholder="TRDBZR5189"
              disabled
            />
            <img className="copy" src={copyIcon} alt="copy icon" />
          </div>

          <div className="social-links">
            <div className="links">
              <span>
                <img src={gmail} alt="gmail icon" />
              </span>
              <span>Invite by email</span>
            </div>
            <div className="links">
              <span>
                <FaFacebook className="link-icon" />
              </span>
              <span>Share to Facebook</span>
            </div>
            <div className="links">
              <span>
                <FaTwitter className="link-icon" />
              </span>
              <span>Share to Twitter</span>
            </div>
            <div className="links">
              <span>
                <FaLinkedin className="link-icon" />
              </span>
              <span>Share to Linkedin</span>
            </div>
            <div className="links">
              <span>
                <FaWhatsapp className="link-icon" />
              </span>
              <span>Share to WhatsApp</span>
            </div>
          </div>
        </div>
      </section> */}

      {/* <footer>
        <p>© Tradebuza 2020, All Rights Reserved</p>
      </footer> */}
      {modalIsOpen && (
        <ModalMessage handleModalClose={handleModalClose} msg={msg} />
      )}
    </div>
  );
};

export default Referral;
