import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { toast } from "react-toastify";
import axios from "axios";
import { TRADEBUZA_BLOG_URL } from "../../../configs/api";
// import { Link } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { listCategories } from "../../../redux/actions/Auth";
import "./AdminHome.scss";

const AdminHome = () => {
  const dispatch = useDispatch();
  const Auth = useSelector((state) => state.Auth);

  console.log("AuthCat", Auth?.categories);
  // const [value, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [category_id, setCategoryId] = useState("");
  const [title, setTitle] = useState("");
  const [meta_title, setMetaTitle] = useState("");
  const [content, setContent] = useState("");
  const [cover, setCover] = useState(null);


  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  // upload image onchange
  const handleChange = (e) => {
    setCover(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let formData = new FormData();
    formData.append(`cover`, cover);
    formData.append(`title`, title);
    formData.append(`meta_title`, meta_title);
    formData.append(`published`, 1);
    formData.append(`category_id`, category_id);
    formData.append(`content`, content);

    const token = localStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const res = await axios.post(`${TRADEBUZA_BLOG_URL}/posts`, formData, config);
      console.log("resgggg", res);
      toast.success("Blog Post created Successful");
      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
      toast.error("Cannot publish Blog Post");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch(listCategories());
  }, [dispatch]);

  return (
    <div className='add-blog-wrapper'>
      <h1 className='heading'>Add Blog</h1>
      <form className='form'>
        <div>
          <div className='form-group'>
            <label className=''>Title</label>
            <input
              type='text'
              className=''
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className='form-group'>
            <label className=''>Description</label>
            <ReactQuill
              theme='snow'
              value={content}
              onChange={setContent}
              modules={modules}
              formats={formats}
            />
          </div>
        </div>
        <div>
          <div className='form-group'>
            <label className=''>Category</label>
            <select
              className=''
              // value={category_id}
              onChange={(e) => setCategoryId(e.target.value)}
            >
              <option>Select Category</option>
              {Auth?.categories &&
                Auth?.categories?.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.attributes?.title}
                  </option>
                ))}
            </select>
          </div>

          <div className='form-group'>
            <label className=''>Meta Title</label>
            <input
              type='text'
              className=''
              onChange={(e) => setMetaTitle(e.target.value)}
            />
          </div>

          <div className='form-group'>
            <input type='file' onChange={handleChange} />
          </div>
          <div className='form-group'>
            <button className='cta' type='subit' onClick={handleSubmit}>
              {isLoading ? "Loading..." : "PUBLISH"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AdminHome;
