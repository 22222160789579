import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import SecondNav from "../../components/secondNav/SecondNav";
import "./CaptureWeb.scss";
import bannerImage from "../../assets/img/banner-image.svg";
import Icon from "../../assets/img/Icon.svg";
import Icon1 from "../../assets/img/Icon (1).svg";
import Icon3 from "../../assets/img/Icon (3).svg";
import farmerImage from "../../assets/img/farmer-image.svg";
import Footer from "../../components/footer/Footer";

const CaptureWeb = () => {
  return (
    <>
      <Helmet>
        <title>TradeBuza - Capture</title>
        <meta
          name='description'
          content='Digital Agricultural Data Capture and Field Enumeration.'
        />
        <link rel='canonical' href='https://www.tradebuza.com/capture' />
      </Helmet>
      <div className='page__wrapper'>
        <div className='rt-bg'></div>
        <SecondNav
          service='/capture-services'
          logIn='http://agro-demo.tradebuza.com'
        />
        <div className='page__container'>
          <div className='hero__wrapper'>
            <div className='hero__img__sm'>
              <img src={bannerImage} alt='img' srcSet='' />
            </div>
            <div className='hero__text'>
              <h4 className='banner-title'>
                {/* TradeBuza <span className='underline'>CAPTURE</span> */}
                TradeBuza Capture
              </h4>
              <h4 className='banner-heading'>
                Digital Agricultural Data Capture and Field Enumeration
              </h4>
              <p className='banner-subtitle'>
                Perform KYC collection, validation, field GPS mapping,
                customized reporting, and eliminate false readings.
              </p>
              <Link to='/get-started'>
                <button className='banner-button'>Schedule a Demo</button>
              </Link>
            </div>
            <div className='hero__img'>
              <img src={bannerImage} alt='img' srcSet='' />
            </div>
          </div>

          <div className='banner-cards'>
            <div className='card'>
              <img src={Icon} alt='' srcSet='' />
              <h4 className='card-title'>Custom Data Capture and Management</h4>
              <p className='card-subtitle'>
                Capture real-time data sets to track agricultural operations.
                Leverage our customized validation components to meet quality
                requirements.
              </p>
            </div>
            <div className='card'>
              <img src={Icon1} alt='' srcSet='' />
              <h4 className='card-title'>Field GPS Mapping</h4>
              <p className='card-subtitle'>
                Improve transparency of farm lot activity, promote Geofencing,
                and view images from field agents and supervisors
              </p>
            </div>

            <div className='card'>
              <img src={Icon} alt='' srcSet='' />
              <h4 className='card-title'>Seamless Offline Capabilities</h4>
              <p className='card-subtitle'>
                Replace paper and spreadsheets with our Cloud-Based web and
                mobile solutions to capture data - even offline.
              </p>
            </div>

            <div className='card'>
              <img src={Icon1} alt='' srcSet='' />
              <h4 className='card-title'>User Rights Management</h4>
              <p className='card-subtitle'>
                Manage user access and assign roles and permissions among
                agents, project managers, and field supervisors.
              </p>
            </div>
          </div>

          <div id='api'>
            <div className='api-container'>
              <div className='api__img'>
                <img src={farmerImage} alt='' srcSet='' />
              </div>
              <div className='onboard'>
                <img src={Icon3} alt='' srcSet='' />
                <h4 className='banner-headings'>
                  Onboard users, investors and advisors as you grow
                </h4>
                <p className='banner-subtitle'>
                  Our infrastructure caters to a broad spectrum of clients
                  ranging from large growers, multi-farm corporations, food
                  conglomerates, ag-financing and insurance companies, to
                  governments and Development Finance Institutions who require
                  key last mile operational data to engage these agribusiness.
                </p>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default CaptureWeb;
