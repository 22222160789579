import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  LOADING,
  LIST_CATEGORIES_SUCCESS,
  LIST_BLOGS_SUCCESS,
  GET_BLOGS_SUCCESS,
  LOADING_BLOGS,
  // GET_BLOGS_BY_CATEGORY_SUCCESS,
  DELETE_BLOG_SUCCESS,
  DELETE_BLOG_CATEGORY_SUCCESS,
} from "../actions/Auth";

const initialState = {
  access_token: localStorage.getItem("access_token"),
  isAuthenticated: JSON.parse(localStorage.getItem("data")),
  loading: false,
  data: JSON.parse(localStorage.getItem("data")),
  isLoading: false,
  categories: [],
  updatedCategories: [],
  blogs: [],
  blogsLoading: false,
  blog: [],
  blogsCat: [],
  updateBlog: [],
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOADING_BLOGS:
      return {
        ...state,
        blogsLoading: true,
      };
    case GET_BLOGS_SUCCESS:
      return {
        ...state,
        blog: payload,
      };
    case LIST_BLOGS_SUCCESS:
      return {
        ...state,
        blogs: payload,
        updateBlog: payload,
        blogsLoading: false,
      };
    case DELETE_BLOG_SUCCESS:
      let blogDetails = [];
      blogDetails = state.blogs.filter(
        (blog) => blog?.attributes?.slug !== payload
      );
      return {
        ...state,
        updateBlog: blogDetails,
        blogsLoading: false,
      };
    case LIST_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: payload,
        updatedCategories: payload,
      };
    case DELETE_BLOG_CATEGORY_SUCCESS:
      let blogCatDetails = [];
      blogCatDetails = state.categories.filter(
        (cat) => cat?.id !== payload
      );
      return {
        ...state,
        updatedCategories: blogCatDetails,
        blogsLoading: false,
      };
    case LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("access_token", payload.result?.token);
      localStorage.setItem("data", JSON.stringify(payload?.result?.user));
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: true,
      };
    case LOGOUT:
    case LOGIN_FAIL:
      localStorage.removeItem("access_token");
      localStorage.removeItem("data");
      return {
        ...state,
        access_token: null,
        isAuthenticated: false,
        loading: false,
      };
    default:
      return state;
  }
}
