/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import logoPreview from "../../assets/img/logo-removebg-preview.png";
import home from "../../assets/img/home.svg";
import phone from "../../assets/img/phone.svg";
import { FaInstagram } from "react-icons/fa";
import { FiFacebook } from "react-icons/fi";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaEnvelope } from "react-icons/fa";
import { BsLinkedin } from "react-icons/bs";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="px">
        <div className="footer-grid">
          <div className="special">
            <a href="#main">
              <img src={logoPreview} alt="" srcSet="" className="footer-logo" />
            </a>
            <p className="footer-text mt-3">
              We are on a mission to bridge the financial inclusion gap for
              agriculture in Sub-Saharan Africa.
            </p>
            <div className="logo-grid">
              <a href="https://www.facebook.com/tradebuza" target="_blank">
                <FiFacebook />
              </a>
              <a href="https://www.instagram.com/tradebuza/" target="_blank">
                <FaInstagram />
              </a>
              <a href="https://twitter.com/tradebuza" target="_blank">
                <AiOutlineTwitter />
              </a>
              <a
                href="https://www.linkedin.com/company/tradebuza"
                target="_blank"
              >
                <BsLinkedin />
              </a>
              {/* <a
                href='https://tradebuza-technologies.medium.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                <FaBloggerB />
              </a> */}
            </div>
          </div>
          <div>
            <h4 className="footer-title">Contact Us</h4>
            <ul className="address">
              <li>
                <span className="span-text">
                  <img className="icon" src={home} alt="" srcSet="" />
                  2, Abebe Village Access Road,
                  <br />
                  <span className="foot__text"> Iganmu, Lagos, Nigeria.</span>
                </span>
              </li>
              <li>
                <span className="span-text">
                  <img src={phone} alt="" srcSet="" />
                  <a className="span-text" href="tel:+2349084759645">
                    +234 813 880 3043
                  </a>
                </span>
              </li>
              <li>
                <span className="span-text special">
                  <a href="mailto:contact@tradebuza.com">
                    <FaEnvelope />
                  </a>
                  <a className="span-text" href="mailto:contact@tradebuza.com">
                    contact@tradebuza.com
                  </a>
                </span>
              </li>
            </ul>
          </div>
          <div>
            <h4 className="footer-title">Quick Links</h4>
            <ul className="address">
              <li>
                <Link to="/get-started">Schedule a Demo</Link>
              </li>
              <li>
                <Link to="/referral">Referral</Link>
              </li>
              <li>
                <Link to="/blog">Blog</Link>
              </li>
            </ul>
            {/* <p className='link-text'>
              <a
                href='http://demo.tradebuza.com/'
                target='_blank'
                rel='noreferrer'
              >
                Schedule a Demo
              </a>
            </p>
            <p className='link-text'>
              <Link to='/referral'>Referral</Link>
            </p>
            <p className='link-text'>
              <a
                href='https://tradebuza-technologies.medium.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                Blog
              </a>
            </p> */}
          </div>
        </div>
        <div
          className="last-line"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <p className="end-footer">
            <Link to="/privacy-policy">Privacy Policy &nbsp; &nbsp;</Link>
          </p>
          <p className="end-footer">
            ||&nbsp; &nbsp; © TradeBuza 2024, All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
