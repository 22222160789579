import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listBlogs, listCategories } from "../../../redux/actions/Auth";
import { Link } from "react-router-dom";
import "./BlogPost.scss";
import moment from "moment";

const BlogPost = () => {
  const dispatch = useDispatch();
  const Auth = useSelector((state) => state.Auth);
  const blogsLoading = Auth.blogsLoading;
  const blogs = Auth.blogs;
  const categories = Auth.categories;
  const [catId, setCatId] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [categories1, setCategories1] = useState(true);

  const handleCategories1 = (id) => {
    setCategories1(true);
    setCatId(id);
  };

  useEffect(() => {
    dispatch(listBlogs());
    dispatch(listCategories());
  }, [dispatch]);

  useEffect(() => {
    setCatId(categories[0]?.id);
  }, [categories]);

  useEffect(() => {
    dispatch(listBlogs(pageNumber));
  }, [dispatch, pageNumber]);

  return (
    <div className='blog-post-wrapper'>
      <div className='link-title'>
        <p>
          <Link to='/blog'>Blog</Link> <Link to='#'> / Categories</Link>
        </p>
      </div>

      <div className="wrapper-container">
        <div className='categories-wrapper'>
          <div className='categories-header'>
            <ul>
              {categories.map((category) => (
                <li key={category.id}>
                  <span
                    className={
                      categories1 && category.id === catId
                        ? "categories-btn active"
                        : "categories-btn"
                    }
                    onClick={() => handleCategories1(category.id)}
                  >
                    {category?.attributes?.title}
                  </span>
                </li>
              ))}
            </ul>
          </div>

          <div className='blog-content'>
            <div className='categories-content-1'>
              {blogsLoading ? (
                <p>Loading...</p>
              ) : (
                <>
                  {blogs &&
                    blogs
                      .filter((blog) => blog?.included?.category?.id === catId)
                      // .slice(-3)
                      .map((blog) => (
                        <div key={blog.id} className='categories-content-1-1'>
                          <Link to={`/blog/${blog?.attributes?.slug}`}>
                            <div className='content-msg'>
                              <h1>{blog?.attributes?.title}</h1>
                              <p>{blog?.attributes?.meta_title}</p>
                              <p className="blog-date">
                                {moment(blog?.attributes?.created_at).format(
                                  "DD MMMM, YYYY"
                                )}
                              </p>
                              {/* <p className='category-tag'>
                              {blog?.included?.category?.attributes?.title}
                            </p> */}
                            </div>
                          </Link>
                          <img src={blog?.attributes?.cover} alt='img' />
                        </div>
                      ))}
                </>
              )}

              <div className='pagination-wrapper'>
                <button
                  className='pagination-button'
                  onClick={() => {
                    if (pageNumber > 1) {
                      setPageNumber(pageNumber - 1);
                    }
                  }}
                >
                  Previous
                </button>
                <button
                  className='pagination-button'
                  onClick={() => {
                    if (blogs?.meta?.total_pages > pageNumber) {
                      setPageNumber(pageNumber + 1);
                    }
                    setPageNumber(pageNumber + 1);
                  }}
                >
                  Next
                </button>
              </div>
            </div>

            <div></div>

            {/* <div className='blog-content-1'>
              <div className='blog-content-1-1'>
                <div className='blog-name-title'>
                  <p className='blog-title-name'>Lateef Jakande</p>
                </div>
                <h1>
                  World Food Prize Laureates Send Open Letter to President Biden
                </h1>
                <p>
                  Celebrate the accomplishments of those who have advanced human
                  development by improving the quality, quantity, or
                  availability of food in the world, by...
                </p>{" "}
                <div className='blog-post-footer'>
                  <p>10 mins ago •</p>
                  <p>Technology</p>
                </div>
              </div>
              <div className='blog-post-img'>
                <img src='' alt='' srcset='' />
              </div>
            </div> */}
            {/* </div> */}

            {/* <div className='blog-tags'>helo</div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
