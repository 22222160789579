import React, { useState } from "react";
import "./PrivacyPolicyHero.scss";

const PrivacyPolicyHero = () => {
  return (
    <div className="policy">
      <div className="policy_header">
        <h1>Privacy Policy</h1>
      </div>
      <div className="cta1__wrapper">
        <div className="content">
          <h2>1. Introduction </h2>
          <p>
            Welcome to TradeBuza. We value your privacy and are committed to
            protecting your personal information. This Privacy Policy outlines
            the types of information we collect from users of our website, how
            we use, share, and protect that information, and your rights
            concerning your personal data.
          </p>
          <p>
            The Sites covered by this Policy include our existing websites,
            mobile applications and all other additional websites and mobile
            applications produced and managed by TradeBuza Technologies. Details
            of existing Sites include the following:
          </p>
          <p>
            TradeBuza -{" "}
            <a href="https://www.tradebuza.com/">https://www.tradebuza.com/</a>;
            accessible from{" "}
            <a href="https://www.tradebuza.com/">https://www.tradebuza.com/ </a>
            <br />
            CropGate -{" "}
            <a href="https://www.cropgate.co/">https://www.cropgate.co/</a>;
            accessible from{" "}
            <a href="https://www.cropgate.co/">https://www.cropgate.co/</a>
          </p>

          <p>
            We value the trust you place in us and understand that your privacy
            is of utmost importance to you. In light of this, we make use of the
            highest standards to ensure secure transactions and the privacy of
            customer information.
          </p>
          <p>
            We are committed to protecting your personal data (i.e. any
            information you provide to us through which you can be identified)
            in accordance with the provisions of the Nigeria Data Protection Act
            2023 and other applicable data protection laws (“Data Protection
            Laws”).
          </p>
          <p>
            By visiting the Sites (including all websites and mobile
            applications which may be added or removed from time to time) you
            agree to the terms and conditions of this Privacy Policy. If you do
            not want your personal data processed in accordance with this
            Policy, please do not use or access the Sites or the Services.
          </p>
          <p>
            We reserve the right, at our sole discretion, to alter and update
            this Policy from time to time. We therefore invite you to review the
            current version of this Policy each time you return to the Sites.
          </p>

          <h2 className="policy-list"> 2. Eligibility </h2>
          <p>
            The Sites and Services are intended solely for persons who, if they
            are natural persons, are eighteen (18) years of age or older, and
            any registration by, use of or access to the Sites and Services by
            any natural person under eighteen (18) is unauthorised, unlicensed
            and in violation of this Policy.
          </p>

          <h2 className="policy-list"> 3. Information </h2>
          <p>
            We Collect We may collect and process the following types of
            information:{" "}
          </p>

          <p>
            &#8226; Personal Information: Information that you voluntarily
            provide to us, such as your name, email address, phone number,
            company name, and job title when you sign up, contact us, or use our
            services.
            <br />
            &#8226; Usage Data: Information is collected automatically as you
            navigate our website, including your IP address, browser type,
            operating system, referring URLs, and the pages you visit.
            <br />
            &#8226; Cookies and Tracking Technologies: We use cookies and
            tracking technologies to enhance your experience on our website,
            remember your preferences, and analyse website usage.
          </p>

          <h2 className="policy-list"> 4. How We Use Your Information </h2>
          <p>
            We use the information we collect for the following purposes:
            <br />
            &#8226; To Provide and Improve Our Services: We use your information
            to operate, maintain, and enhance our website and services,
            including responding to your inquiries and providing customer
            support.
            <br />
            &#8226; To Communicate with You: We may use your contact information
            to send you updates, newsletters, marketing communications, and
            other information that may interest you. You can opt out of
            receiving these communications at any time.
            <br />
            &#8226; To Analyze and Improve Website Performance: We use usage
            data and tracking technologies to monitor and analyse trends, usage,
            and activities on our website, helping us improve its functionality
            and user experience.
            <br />
            &#8226; To Comply with Legal Obligations: We may use and disclose
            your information to comply with applicable laws, regulations, and
            legal processes.
          </p>

          <h2 className="policy-list"> 5. Sharing Your Information </h2>
          <p>
            We do not sell, rent, or trade your personal information. However,
            we may share your information with third parties in the following
            circumstances:
            <p>
              &#8226; Service Providers: We may share your information with
              third-party service providers who assist us in operating our
              website, conducting our business, or servicing you, as long as
              those parties agree to keep your information confidential.
              <br />
              &#8226; Legal Compliance: We may disclose your information if
              required by law or in response to valid legal requests, such as
              subpoenas or court orders.
            </p>
          </p>

          <h2 className="policy-list"> 6. Data Security </h2>
          <p>
            We take reasonable measures to protect your personal information
            from unauthorised access, use, alteration, and disclosure.
          </p>

          <h2 className="policy-list"> 7. Your Rights </h2>
          <p>
            You have the following rights concerning your personal information:
            <br />
            &#8226; Access: You have the right to request access to the personal
            information we hold about you.
            <br />
            &#8226; Correction: You have the right to request corrections to any
            inaccurate or incomplete personal information.
            <br />
            &#8226; Deletion: You have the right to request the deletion of your
            personal information, subject to certain legal exceptions.
            <br />
            &#8226; Opt-Out: You can opt out of receiving marketing
            communications from us at any time by following the unsubscribe
            instructions in those communications.
            <br />
            To exercise these rights, please contact us at{" "}
            <a className="span-text" href="mailto:info@tradebuza.com">
              info@tradebuza.com
            </a>
          </p>

          <h2 className="policy-list"> 8. Cookies </h2>
          <p>
            We use data collection devices such as ‘cookies’ on certain pages of
            the Sites. Cookies are small files stored on your hard drive that
            assist us in providing Services customised to your requirements and
            tastes. We also offer certain features that are only available
            through the use of a ‘cookie’. Cookies can also help us provide
            information targeted to your interests. Cookies may be used whether
            you choose to register with Us or not.{" "}
          </p>
          <p>
            We also use cookies to allow you to enter your password less
            frequently during a session. Most cookies are ‘session cookies’,
            meaning they are automatically deleted from your hard drive at the
            end of a session. You are always free to decline our cookies if your
            browser permits, although in that case you may not be able to use
            certain features on the Sites and you may be required to re-enter
            your password more frequently during a session. A cookie cannot read
            data off your hard disk or read cookie files created by other sites.
            A cookie is not linked to personally identifiable information while
            on the Sites. Once you close your browser, the cookie simply
            terminates. For instance, by setting a cookie on your browser, you
            would not have to log in a password more than once, saving time
            while on the Sites.{" "}
          </p>
          <p>
            You can choose whether to accept cookies by changing the settings of
            your browser. You can reset your browser to refuse all cookies or
            allow your browser to show you when a cookie is being sent. If you
            reject the cookies on the Sites, you may still be able to use the
            Sites, but it shall be limited to certain minimal functionality. The
            only drawback to this is that you may be limited to some areas of
            Sites or limited to certain functions of the Sites.
          </p>

          <h2 className="policy-list"> 9. Disclaimer </h2>
          <p>
            We will keep your information secure by taking appropriate technical
            and organisational measures against its unauthorised or unlawful
            processing and its accidental loss, destruction or damage. We will
            do our best to protect your personal data, but we cannot guarantee
            the security of your personal data which is transmitted to other
            websites via an internet or similar connection. We encourage you to
            review the privacy policies of those websites before providing any
            personal information. If we have given you (or you have chosen) a
            password to access certain areas of the Sites please keep this
            password safe, we will not share this password with anyone.
          </p>
          <p>
            As a user of the Services, you understand and agree that you assume
            all responsibility and risk attached to safeguarding your account
            with us. You shall at no time whatsoever disclose your password to
            anyone, nor shall you allow anyone make use of your account.
          </p>

          <h2 className="policy-list"> 10. Changes to This Privacy Policy </h2>
          <p>
            We are constantly trying to improve our Sites and Services, so we
            may need to change this Policy from time to time as well. We will
            alert you of material changes by, for example, placing a notice on
            our websites and/or by sending you an email (if you have registered
            your e-mail details with us) when we are required to do so by
            applicable law. We reserve the right to update this Policy as we
            deem fit, from time to time, without any intimation to you and your
            continued use of the Sites will signify your acceptance of any
            amendment to these terms.
          </p>
          <p>
            Our updated terms will also be displayed on our website (
            <a href="https://www.tradebuza.com/" target="_blank">
              www.tradebuza.com
            </a>
            ). It is your responsibility to check this Privacy Policy from time
            to time to verify such updates.
          </p>

          <h2 className="policy-list"> 11. Contact Us</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy or
            our data practices, please contact us at:
          </p>

          <div
            className=""
            style={{
            paddingLeft: "35px",
            }}
          >
            TradeBuza Technologies
            <br />
            2 Abebe Village Access Road, Iganmu, Lagos 102232, Lagos
            <br />
            <a className="span-text" href="mailto:info@tradebuza.com">
              info@tradebuza.com
            </a>
          </div>
          <p>Last Updated: August 2024</p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyHero;
