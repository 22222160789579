import React from "react";
import SecondNav from "../../components/secondNav/SecondNav";
import "./CaptureWeb.scss";
import farmerImage from "../../assets/img/farmer-image.svg";
import Footer from "../../components/footer/Footer";

const CaptureService = () => {
  return (
    <div className='page__wrapper'>
      <div className="rt-bg2">
      </div>
      <SecondNav service="/capture-services"  logIn="https://tie.tradebuza.com/dashboard" />
      <div className='page__container'>
        <div className='hero__wrapper'>
          <div className='hero__img__sm'>
            <img src={farmerImage} alt='img' srcSet='' />
          </div>
          <div className='hero__text'>
            <h4 className='banner-heading'>TradeBuza Capture</h4>
            <p className='banner-subtitle'>
              An end-to-end field enumeration and data management system that
              allows users perform some key features including - Data
              standardisation, offline capabilities, device versatility, data
              loss protection and GPS capture capabilities. The combination of
              all these features which have been built in based on user
              interactions and requests over the years gives us a competitive
              advantage.
            </p>
          </div>
          <div className='hero__img'>
            <img src={farmerImage} alt='img' srcSet='' />
          </div>
        </div>

        <div className='banner-cards'>
          <div className='card'>
            <h4 className='card-title'> Custom Data Capture and Management</h4>
            <p className='card-subtitle'>
              The system has the ability to capture as many real-time data sets,
              and use structured validation components to ensure captured data
              meet required types and ranges according to user needs.
            </p>
          </div>
          <div className='card'>
            <h4 className='card-title'>Field GPS Mapping</h4>
            <p className='card-subtitle'>
              This maps and saves GPS coordinates of a particular farm at a
              time, to provide visibility of farm lot, track activity, promote
              Geo-fencing and easily view pictures of activities to monitor the
              status.
            </p>
          </div>

          <div className='card'>
            <h4 className='card-title'>Seamless Offline Capabilities</h4>
            <p className='card-subtitle'>
              No matter if your device is online or offline, the offline
              capability feature enables seamless tracking of the number of data
              added to the queue.
            </p>
          </div>

          <div className='card'>
            <h4 className='card-title'>User Rights Management</h4>
            <p className='card-subtitle'>
              It allows for a multi-tiered user group process that ensures
              high-level superuser privileges among several administrators and
              process rights management, which are then assigned to users
            </p>
          </div>
          
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default CaptureService;
