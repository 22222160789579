import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteBlog, listBlogs } from "../../../redux/actions/Auth";
import moment from "moment";
import "./ViewBlogs.scss";

const ViewBlogs = () => {
  const dispatch = useDispatch();
  const Auth = useSelector((state) => state.Auth);
  const updateBlog = Auth?.updateBlog;
  const blogsLoading = Auth?.blogsLoading;
  const [pageNumber, setPageNumber] = useState(1);

  const handleDeleteBlog = (id) => {
    dispatch(deleteBlog(id));
  };

  useEffect(() => {
    dispatch(listBlogs(pageNumber));
  }, [dispatch, pageNumber]);

  return (
    <div className='blog-wrapper-admin'>
      <h1 className='heading'>Blogs</h1>

      <div className='pagination-wrapper'>
        <button
          className='pagination-button'
          onClick={() => {
            if (pageNumber > 1) {
              setPageNumber(pageNumber - 1);
            }
          }}
        >
          Previous
        </button>
        <button
          className='pagination-button'
          onClick={() => {
            if (updateBlog?.meta?.total_pages > pageNumber) {
              setPageNumber(pageNumber + 1);
            }
            setPageNumber(pageNumber + 1);
          }}
        >
          Next
        </button>
      </div>

      {blogsLoading ? (
        <p>loading...</p>
      ) : (
        updateBlog?.map((blog) => (
          <div className="wrap-posts">
            <div className='blog-post-wrapperz' key={blog.id}>
              <div className='blog-contentz'>
                <div className='blog-post-img'>
                  <img src={blog?.attributes?.cover} alt='img' />
                </div>
                <div className='blog-content-1'>
                  <h1>{blog?.attributes?.title}</h1>
                  <p>{blog?.attributes?.meta_title}</p>
                  <div className='blog-post-footer'>
                    <p>
                      {moment(blog?.attributes?.created_at).format(
                        "DD MMMM, YYYY"
                      )}
                    </p>
                    <p className='category-tag'>
                      {blog?.included?.category?.attributes?.title}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='blog-cta'>
              <ul>
                {/* <li>
      <button className='btn btn-primary'>Edit</button>
    </li> */}
                <li>
                  <button
                    onClick={() => handleDeleteBlog(blog?.attributes?.slug)}
                    className='btn btn-danger'
                  >
                    Delete
                  </button>
                </li>
              </ul>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default ViewBlogs;
