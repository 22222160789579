import React from "react";
import "./Banner.scss";
import rightGroup from "../../assets/img/left-group.svg";
import leftGroup from "../../assets/img/right-group.svg";
import img1 from "../../assets/img/img1.png";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <section className='banner__wrapper'>
      <div className='left-pattern'>
        <img src={leftGroup} alt='' srcSet='' />
      </div>
      <div className='right-pattern'>
        <img src={rightGroup} alt='' srcSet='' />
      </div>
      <div className='px'>
        <div className='banner-image-sm'>
          <img src={img1} alt='farm scientist' srcSet='' />
        </div>
        <div className='banner'>
          <div className='banner-title'>
            <h4>Digital Agriculture Infrastructure for Africa</h4>
            <p className='banner-subtitle'>
              TradeBuza leverages technology to help smallholder farmers access
              agriculture financing.
            </p>
            <Link to='/get-started'>
              <button className='banner-button'>Schedule A Demo</button>
            </Link>
          </div>
          <div className='banner-image'>
            <img src={img1} alt='farm scientist' srcSet='' />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
