import "./App.css";
import { Route, Routes } from "react-router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Homepage from "./pages/HomePage/HomePage";
import Referral from "./pages/ReferralPage/ReferralPage";
import TeamPage from "./pages/TeamPage/TeamPage";
import GetStartedPage from "./pages/GetStartedPage/GetStartedPage";
import OptOutPage from "./pages/OptOutPage/OptOutPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage/PrivacyPolicyPage";
import AgroWeb from "./pages/AgroWeb/AgroWeb";
import AgroService from "./pages/AgroWeb/AgroService";
import CaptureWeb from "./pages/CaptureWeb/CaptureWeb";
import CaptureService from "./pages/CaptureWeb/CaptureService";
import AgroApiService from "./pages/AgroApiWeb/AgroApiService";
import AgroApiWeb from "./pages/AgroApiWeb/AgroApiWeb";
import Blogs from "./pages/Blog/Blogs";
import Blog from "./pages/Blog/Blog";
import BlogPosts from "./pages/Blog/BlogPosts";
import AdminLogin from "./pages/authPages/admin/login/AdminLogin";
import AdminDashboard from "./pages/adminDashboard";
import ViewAllBlogs from "./components/blog/blogPost/ViewAllBlogs";
import ScrollTop from "./components/ScrollTop/ScrollTop";

function App() {
  return (
    <div className='App'>
      <ToastContainer autoClose={3000} />
      <ScrollTop>
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='/referral' element={<Referral />} />
          <Route path='/teampage' element={<TeamPage />} />
          <Route path='/get-started' element={<GetStartedPage />} />
          <Route path='/opt-out-request' element={<OptOutPage />} />
          <Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
          <Route path='/agro' element={<AgroWeb />} />
          <Route path='/agro-services' element={<AgroService />} />
          <Route path='/agro-api' element={<AgroApiWeb />} />
          <Route path='/agroapi-services' element={<AgroApiService />} />
          <Route path='/capture' element={<CaptureWeb />} />
          <Route path='/capture-services' element={<CaptureService />} />
          <Route path='/blog' element={<Blogs />} />
          <Route path='blog/:id' element={<Blog />} />
          <Route path='/categories' element={<BlogPosts />} />
          <Route path='/all-blogs' element={<ViewAllBlogs />} />
          <Route path='/admin' element={<AdminLogin />}></Route>
          <Route path='admin-dashboard/*' element={<AdminDashboard />}></Route>
        </Routes>
      </ScrollTop>
    </div>
  );
}

export default App;
