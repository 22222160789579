import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  // faPlus,
} from "@fortawesome/free-solid-svg-icons";
import "./Slider.scss";
import { TRADEBUZA_BLOG_URL } from "../../../configs/api";
import axios from "axios";
import { Link } from "react-router-dom";

export default class CardSlider extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.state = {
      roles: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
     axios
      .get(`${TRADEBUZA_BLOG_URL}/posts`)
      .then((response) => {
        this.setState({ roles: response.data?.result?.data, isLoading: false });
        console.log("hhh", response.data?.result);
      });
  }

  next() {
    this.slider.slickNext();
  }

  previous() {
    this.slider.slickPrev();
  }

  render() {
    // console.log("hhh", this.state.roles);
    var settings = {
      className: "variable-width",
      variableWidth: true,
      adaptiveHeight: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div className='slider-wrapper'>
        {!this.state.isLoading ? (
          <>
            <Slider ref={(c) => (this.slider = c)} {...settings}>
              {this.state.roles.map((role, index) => (
                <div className='role-card' key={index}>
                  <Link to={`/blog/${role.attributes.slug}`}>
                  <div className='role-card-image'>
                    <img src={role.attributes.cover} alt='role' />
                  </div>
                  <div className='role-card-content'>
                    <h3>{role.attributes.title}</h3>
                    <p>{role.attributes.meta_title}</p>
                  </div>
                  {/* <div className='role-card-footer'>
                    <img src={role.icon} alt='img' />
                    <p>{role.name}</p>
                    <p>{role.role}</p>
                  </div> */}
                  </Link>
                </div>
              ))}
            </Slider>
            <div
              className='prev_next_cta_roles'
              style={{ textAlign: "center" }}
            >
              <button onClick={this.previous}>
                <FontAwesomeIcon className='icons' icon={faChevronLeft} />
              </button>
              <button onClick={this.next}>
                <FontAwesomeIcon className='icons' icon={faChevronRight} />
              </button>
            </div>
          </>
        ) : (
          <div className='loader'>loading .....</div>
        )}
      </div>
    );
  }
}
