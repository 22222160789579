import React, { useEffect, useState } from "react";
import "./Category.scss";
import { toast } from "react-toastify";
import axios from "axios";
import { TRADEBUZA_BLOG_URL } from "../../../configs/api";
import { useDispatch, useSelector } from "react-redux";
import { DELETE_BLOG_CATEGORY_SUCCESS, listCategories } from "../../../redux/actions/Auth";
import { Table } from "react-bootstrap";

const Category = () => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.Auth?.updatedCategories);
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  console.log("categoriesiiii", categories);

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const token = localStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const body = {
      title: value,
    };
    try {
      const res = await axios.post(
        `${TRADEBUZA_BLOG_URL}/categories`,
        body,
        config
      );
      toast.success("Category created Successful");
      console.log("res", res);
      setIsLoading(false);
      dispatch(listCategories());
    } catch (error) {
      toast.error("Cannot add category");
      setIsLoading(false);
      return error;
    }
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    const token = localStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await axios.delete(
        `${TRADEBUZA_BLOG_URL}/categories/${id}`,
        config
      );
      dispatch({
        type: DELETE_BLOG_CATEGORY_SUCCESS,
        payload: id,
      })
      toast.success("Category deleted Successful");
      console.log("res", res);
      setIsLoading(false);
    } catch (error) {
      toast.error("Cannot delete category");
      setIsLoading(false);
      return error;
    }
  };

  useEffect(() => {
    dispatch(listCategories());
  }, [dispatch]);

  return (
    <div className='add-blog-wrapper'>
      <h1 className='heading'>Add Category</h1>

      <div className='section-wrapper'>

        <form className=''>
          <div>
            <div className='form-group'>
              <label className=''>Title</label>
              <input
                type='text'
                className='form-control'
                placeholder='Enter Title'
                name='title'
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <div className='form-group'>
              <button className='cta' type='subit' onClick={handleSubmit}>
                {isLoading ? "Loading..." : "PUBLISH"}
              </button>
            </div>
          </div>
        </form>

        <div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Title</th>
                {/* <th>Edit</th> */}
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {categories?.map((category) => (
                <tr key={category.id}>
                  <td>{category.attributes?.title}</td>
                  <td>
                    {/* </td>
                  <td> */}
                    {/* <button className='btn btn-primary'>Edit</button> */}
                    <button className='btn btn-danger' onClick={() => handleDelete(category.id)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>


      </div>
    </div>
  );
};

export default Category;
