import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/footer/Footer";
import OptOutHero from "../../components/optOutRequest/OptOutHero";
import TopNav from "../../components/TopNav/TopNav";

const OptOutPage = () => {
  return (
    <div>
      <Helmet>
        <title>TradeBuza - Opt Out</title>
        <meta name='description' content='Submit a request.' />
        <link rel='canonical' href='https://www.tradebuza.com/opt-out-request' />
      </Helmet>
      <TopNav />
      <OptOutHero />
      <Footer />
    </div>
  );
};

export default OptOutPage;