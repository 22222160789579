import React from "react";
import { Routes, Route } from "react-router-dom";
import AdminDashboardLayout from "./layout/AdminDashboardLayout";
import AdminHome from "./adminDashboardHome/AdminHome";
import Category from "./category/Category";
import Tag from "./tag/Tag";
import ViewBlogs from "./viewBlogs/ViewBlogs";

const AdminDashboard = () => {
  return (
    <AdminDashboardLayout>
      <Routes>
        <Route path='/' element={<AdminHome />}></Route>
        <Route path='/view-blogs' element={<ViewBlogs />}></Route>
        <Route path='category' element={<Category />}></Route>
        <Route path='tag' element={<Tag />}></Route>
      </Routes>
    </AdminDashboardLayout>
  );
};

export default AdminDashboard;
